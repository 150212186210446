import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Card, Form, Spinner } from "react-bootstrap";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { AxiosError, AxiosResponse } from "axios";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import Icon from "@mdi/react";
import { mdiAccountMultipleOutline, mdiChevronLeft } from "@mdi/js";

import { DateFormat, ModuleEntity, ModulePermissionEntity } from "../../config/defines";
import { SelectOptions, SelectStyle } from "../../config/select";
import { modulePermissionCheck } from "../../config/utils";
import { useToast } from "../../context/ToastContext";
import { useAuth } from "../../context/AuthContext";
import { PersonPostRequest, PersonPostResponse, PersonPutRequest, PersonPutResponse } from "../../entities/PersonEntity";
import AccessControlListService from "../../services/AccessControlListService";
import CompanyService from "../../services/CompanyService";
import PersonService from "../../services/PersonService";

import Layout from "../../components/Layout";

const toastTitle = "Usuário";

export default function UsuarioFormulario() {
	const personService = new PersonService();
	const companyService = new CompanyService();
	const accessControlListService = new AccessControlListService();

	const navigate = useNavigate();
	const { user } = useAuth();
	const { id } = useParams();
	const { handleToast } = useToast();

	const [formStatus, setFormStatus] = useState(id ? false : true);
	const [formSaving, setFormSaving] = useState(false);

	const { data, isLoading, isFetching, isRefetching } = useQuery(["usuario", id], () => fetchData(id), { enabled: !!id && !formStatus });

	const { register, handleSubmit, setValue, reset, watch } = useForm({ defaultValues: data ? { ...data, sendInvite: false } : undefined });
	const companyId = watch("companyId");
	const birthDate = watch("birthDate");

	const queryEmpresas = useQuery(["empresas"], () => fetchDataEmpresas());
	const queryGrupos = useQuery(["grupos", companyId], () => fetchDataGrupos(companyId));

	const mutationPost = useMutation(mutatePost, { onSuccess: mutatePostSuccess, onError: mutateError });
	const mutationPut = useMutation(mutatePut, { onSuccess: mutatePutSuccess, onError: mutateError });

	async function fetchData(id: any) {
		let resp = await personService.get(id);
		reset({ ...resp.data, sendInvite: false });
		return resp.data;
	}

	async function fetchDataEmpresas() {
		let resp = await companyService.getCompanies();
		return resp.data;
	}

	async function fetchDataGrupos(companyId: number) {
		let resp = await accessControlListService.getGroups(0, "", companyId);
		return resp.data;
	}

	async function mutatePost(data: PersonPostRequest) {
		setFormSaving(true);
		return await personService.post(data);
	}

	function mutatePostSuccess(resp: AxiosResponse<PersonPostResponse, any>) {
		handleToast(toastTitle, "Informações salvas com sucesso!", 5000);
		navigate("/usuarioFormulario/" + resp.data.id);
		setFormStatus(false);
		setFormSaving(false);
	}

	async function mutatePut(data: PersonPutRequest) {
		setFormSaving(true);
		return await personService.put(Number(id), data);
	}

	function mutatePutSuccess(resp: AxiosResponse<PersonPutResponse, any>) {
		handleToast(toastTitle, "Informações salvas com sucesso!", 5000);
		setFormStatus(false);
		setFormSaving(false);
	}

	function mutateError(resp: AxiosError) {
		handleToast(toastTitle, `Problema na operação: ${resp.message}`, 5000, "danger");
		setFormSaving(false);
	}

	async function handleDataNascimento(value: any) {
		setValue(`birthDate`, value);
	}

	async function handleEmpresa(value: any) {
		setValue(`companyId`, value ? value.value : undefined);
	}

	async function handleGrupo(value: any) {
		setValue(`groupId`, value ? value.value : undefined);
	}

	const formEmpresaOptions = queryEmpresas.data?.map((item) => {
		return { label: `${item.name}`, value: item.id };
	});

	const formGrupoOptions = queryGrupos.data?.grupos.map((item) => {
		return { label: `${item.nome}`, value: item.grupoId };
	});

	return (
		<Layout>
			<Form
				onSubmit={handleSubmit((values) => {
					if (id) {
						mutationPut.mutate({
							name: values.name,
							email: values.email,
							birthDate: values.birthDate,
							cpf: values.cpf,
							phone: values.phone,
							groupId: values.groupId,
							companyId: values.companyId,
						});
					} else {
						mutationPost.mutate({
							name: values.name,
							email: values.email,
							birthDate: values.birthDate,
							cpf: values.cpf,
							phone: values.phone,
							groupId: values.groupId,
							companyId: values.companyId,
							sendInvite: values.sendInvite,
						});
					}
				})}
			>
				<h5 className="mt-4 mb-4 d-flex align-items-center fw-light">
					<Link to={"/usuarios"} className="d-flex align-items-center text-decoration-none">
						<Icon path={mdiChevronLeft} size={1} className="me-1" /> <Icon path={mdiAccountMultipleOutline} size={1} className="me-1" /> Formulário de Usuário
					</Link>
					{(isLoading || isFetching || isRefetching) && <Spinner size="sm" className="ms-1" variant="secondary" />}
					<div className="d-flex gap-2 float-right ms-auto" style={{ marginTop: -10, marginBottom: -10 }}>
						{modulePermissionCheck(user!, ModuleEntity.acessos, ModulePermissionEntity.editar) && (
							<>
								{!formStatus && (
									<Button
										variant="light"
										className="rounded-3 shadow-sm"
										onClick={() => {
											setFormStatus(true);
										}}
									>
										Editar Informações
									</Button>
								)}
								{formStatus && (
									<>
										<Button
											variant="light"
											className="rounded-3 shadow-sm"
											type="button"
											onClick={() => {
												if (id) {
													setFormStatus(false);
												} else {
													navigate("/usuarios");
												}
											}}
											disabled={formSaving}
										>
											Cancelar
										</Button>
										<Button variant="primary" className="rounded-3 shadow-sm" type="submit" disabled={formSaving}>
											{formSaving ? (
												<>
													<Spinner animation="border" size="sm" className="me-2" /> Salvando
												</>
											) : (
												"Salvar Informações"
											)}
										</Button>
									</>
								)}
							</>
						)}
					</div>
				</h5>

				<Card className="mb-3">
					<Card.Body>
						<Form.Group className="mb-3" controlId="nome">
							<Form.Label>Nome</Form.Label>
							<Form.Control type="text" placeholder="Informe aqui" {...register("name", { required: true })} disabled={!formStatus} />
						</Form.Group>
						<Form.Group className="mb-3" controlId="email">
							<Form.Label>E-mail</Form.Label>
							<Form.Control type="email" placeholder="Informe aqui" {...register("email", { required: true })} disabled={!formStatus} />
						</Form.Group>
						<Form.Group className="mb-3" controlId="cpf">
							<Form.Label>CPF</Form.Label>
							<Form.Control type="text" placeholder="Informe aqui" {...register("cpf", { required: true })} disabled={!formStatus} />
						</Form.Group>
						<Form.Group className="mb-3" controlId="phone">
							<Form.Label>Telefone</Form.Label>
							<Form.Control type="text" placeholder="Informe aqui" {...register("phone", { required: true })} disabled={!formStatus} />
						</Form.Group>
						<Form.Group className="mb-3" controlId="birthDate">
							<Form.Label>Data de Nascimento</Form.Label>
							<ReactDatePicker
								placeholderText="Data"
								className="form-control"
								calendarClassName="shadow-lg border"
								onChange={(value) => {
									handleDataNascimento(moment(value ?? "").format(DateFormat));
								}}
								dayClassName={(date) => {
									return moment(date).isSame(moment(birthDate, DateFormat), "date") ? "btn btn-primary p-1" : "btn btn-light p-1";
								}}
								selected={birthDate ? moment(birthDate, DateFormat).toDate() : undefined}
								dateFormat="dd/MM/yyyy"
								disabled={!formStatus}
							/>
						</Form.Group>
						{user?.superUser && (
							<Form.Group className="mb-3" controlId="empresa">
								<Form.Label>Empresa</Form.Label>
								{(!queryEmpresas.isFetched || isLoading) && (
									<div className="form-control disabled">
										<Spinner size="sm" />
									</div>
								)}
								{queryEmpresas.isFetched && !isLoading && (
									<Select
										placeholder={"Selecione"}
										defaultValue={formEmpresaOptions?.find((option) => {
											return option.value === data?.companyId;
										})}
										options={formEmpresaOptions}
										onChange={(value) => {
											handleEmpresa(value);
										}}
										styles={SelectStyle}
										isDisabled={!formStatus || !!id}
										{...SelectOptions}
									/>
								)}
							</Form.Group>
						)}
						<Form.Group className="mb-3" controlId="grupoPermissao">
							<Form.Label>Grupo de Permissão</Form.Label>
							{(!queryGrupos.isFetched || isLoading) && (
								<div className="form-control disabled">
									<Spinner size="sm" />
								</div>
							)}
							{queryGrupos.isFetched && !isLoading && (
								<Select
									placeholder={"Selecione"}
									defaultValue={formGrupoOptions?.find((option) => {
										return option.value === data?.groupId;
									})}
									options={formGrupoOptions}
									onChange={(value) => {
										handleGrupo(value);
									}}
									styles={SelectStyle}
									isDisabled={!formStatus}
									{...SelectOptions}
								/>
							)}
						</Form.Group>
						{!id && (
							<Form.Group className="mb-3 col-md-3" controlId={`sendInvite`}>
								<Form.Label>Enviar Convite</Form.Label>
								<Form.Check type="switch" className="pt-1" {...register(`sendInvite`)} disabled={!formStatus} />
							</Form.Group>
						)}
					</Card.Body>
				</Card>

				{modulePermissionCheck(user!, ModuleEntity.acessos, ModulePermissionEntity.editar) && (
					<div className="d-flex gap-2 mb-4">
						{!formStatus && (
							<Button
								variant="light"
								className="rounded-3 shadow-sm"
								onClick={() => {
									setFormStatus(true);
								}}
							>
								Editar Informações
							</Button>
						)}
						{formStatus && (
							<>
								<Button variant="primary" className="rounded-3 shadow-sm" type="submit" disabled={formSaving}>
									{formSaving ? (
										<>
											<Spinner animation="border" size="sm" className="me-2" /> Salvando
										</>
									) : (
										"Salvar Informações"
									)}
								</Button>
								<Button
									variant="light"
									className="rounded-3 shadow-sm"
									type="button"
									onClick={() => {
										if (id) {
											setFormStatus(false);
										} else {
											navigate("/usuarios");
										}
									}}
									disabled={formSaving}
								>
									Cancelar
								</Button>
							</>
						)}
					</div>
				)}
			</Form>
		</Layout>
	);
}
