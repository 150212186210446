import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Card, Col, Form, InputGroup, Row, Spinner } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import { AxiosError, AxiosResponse } from "axios";
import Icon from "@mdi/react";
import { mdiChevronLeft, mdiDatabaseOutline, mdiPlus, mdiTrashCanOutline } from "@mdi/js";

import { ModuleEntity, ModulePermissionEntity } from "../../config/defines";
import { modulePermissionCheck } from "../../config/utils";
import { useToast } from "../../context/ToastContext";
import { useAuth } from "../../context/AuthContext";
import { DomainOptionDeleteResponse, DomainOptionPostRequest, DomainOptionPostResponse, DomainOptionPutResponse } from "../../entities/DomainOptionEntity";
import { DomainEntity, DomainEntityGetResponse, DomainEntityPostRequest, DomainEntityPostResponse } from "../../entities/DomainEntity";
import DomainOptionService from "../../services/DomainOptionService";
import DomainService from "../../services/DomainService";

import Layout from "../../components/Layout";

const toastTitle = "Domínio";

export default function DominioFormulario() {
	const domainService = new DomainService();
	const domainOptionService = new DomainOptionService();

	const navigate = useNavigate();
	const { user } = useAuth();
	const { id } = useParams();
	const { handleToast } = useToast();

	const [formStatus, setFormStatus] = useState(id ? false : true);
	const [formSaving, setFormSaving] = useState(false);

	const { data, isLoading, isFetching, isRefetching } = useQuery<DomainEntityGetResponse>(["dominio", id], () => fetchData(id), { enabled: !!id && !formStatus });

	const mutationPost = useMutation(mutatePost, { onSuccess: mutatePostSuccess, onError: mutateError });
	const mutationPut = useMutation(mutatePut, { onSuccess: mutatePutSuccess, onError: mutateError });
	const mutationOptionPost = useMutation(mutateOptionPost, { onSuccess: mutateOptionPostSuccess, onError: mutateError });
	const mutationOptionDelete = useMutation(mutateOptionDelete, { onSuccess: mutateOptionDeleteSuccess, onError: mutateError });

	const { register, control, handleSubmit, reset } = useForm({ defaultValues: data });
	const { fields, append, remove } = useFieldArray({ name: "domainOptions", control: control });

	async function fetchData(id: any) {
		const resp = await domainService.get(id);
		reset(resp.data);
		return resp.data;
	}

	async function mutatePost(data: DomainEntityPostRequest) {
		setFormSaving(true);
		return await domainService.post(data);
	}

	function mutatePostSuccess(resp: AxiosResponse<DomainEntityPostResponse, any>) {
		handleToast(toastTitle, "Informações salvas com sucesso!", 5000);
		navigate("/dominioFormulario/" + resp.data.id);
		setFormStatus(false);
		setFormSaving(false);
	}

	async function mutatePut(data: DomainEntity) {
		setFormSaving(true);

		// DOMAIN
		let resp = await domainService.put(Number(id), {
			title: data.title,
		});

		// OPTIONS
		for (let i in data.domainOptions) {
			let item = data.domainOptions[Number(i)];
			resp = await domainOptionService.put(item.id!, {
				title: item.title,
				order: item.order,
				value: item.value,
			});
		}

		return resp!;
	}

	function mutatePutSuccess(resp: AxiosResponse<DomainOptionPutResponse, any>) {
		handleToast(toastTitle, "Informações salvas com sucesso!", 5000);
		setFormStatus(false);
		setFormSaving(false);
	}

	async function mutateOptionPost(data: DomainOptionPostRequest) {
		setFormSaving(true);
		return await domainOptionService.post(Number(id), data);
	}

	function mutateOptionPostSuccess(resp: AxiosResponse<DomainOptionPostResponse, any>) {
		append(resp.data);
		handleToast(toastTitle, "Informações salvas com sucesso!", 5000);
		setFormSaving(false);
	}

	async function mutateOptionDelete({ id, index }: { id: number; index: number }) {
		setFormSaving(true);
		let resp = await domainOptionService.delete(id);
		return { index: index, resp: resp };
	}

	function mutateOptionDeleteSuccess({ index, resp }: { index: number; resp: AxiosResponse<DomainOptionDeleteResponse, any> }) {
		remove(index);
		handleToast(toastTitle, "Informações salvas com sucesso!", 5000);
		setFormSaving(false);
	}

	function mutateError(resp: AxiosError) {
		handleToast(toastTitle, `Problema na operação: ${resp.message}`, 5000, "danger");
		setFormSaving(false);
	}

	function handleAdicionar() {
		mutationOptionPost.mutate({ id: undefined, title: "", order: fields.length, value: null });
	}

	function handleRemover(id: number, index: number) {
		mutationOptionDelete.mutate({ id, index });
	}

	return (
		<Layout>
			<Form
				onSubmit={handleSubmit((values) => {
					if (id) {
						mutationPut.mutate(values);
					} else {
						mutationPost.mutate({
							title: values.title,
							domainOptions: [],
						});
					}
				})}
			>
				<h5 className="mt-4 mb-4 d-flex align-items-center fw-light">
					<Link to={"/configuracoesDominios"} className="d-flex align-items-center text-decoration-none">
						<Icon path={mdiChevronLeft} size={1} className="me-1" /> <Icon path={mdiDatabaseOutline} size={1} className="me-1" /> Formulário de Domínio
					</Link>
					{(isLoading || isFetching || isRefetching) && <Spinner size="sm" className="ms-1" variant="secondary" />}
					<div className="d-flex gap-2 float-right ms-auto" style={{ marginTop: -10, marginBottom: -10 }}>
						{modulePermissionCheck(user!, ModuleEntity.configuracoesDominios, ModulePermissionEntity.editar) && (
							<>
								{!formStatus && (
									<Button
										variant="light"
										className="rounded-3 shadow-sm"
										onClick={() => {
											setFormStatus(true);
										}}
									>
										Editar Informações
									</Button>
								)}
								{formStatus && (
									<>
										<Button
											variant="light"
											className="rounded-3 shadow-sm"
											type="button"
											onClick={() => {
												if (id) {
													setFormStatus(false);
												} else {
													navigate("/dominios");
												}
											}}
											disabled={formSaving}
										>
											Cancelar
										</Button>
										<Button variant="primary" className="rounded-3 shadow-sm" type="submit" disabled={formSaving}>
											{formSaving ? (
												<>
													<Spinner animation="border" size="sm" className="me-2" /> Salvando
												</>
											) : (
												"Salvar Informações"
											)}
										</Button>
									</>
								)}
							</>
						)}
					</div>
				</h5>

				<Card className="mb-4">
					<Card.Header className="d-flex align-items-center bg-white fs-5 fw-light p-3">Domínio</Card.Header>
					<Card.Body>
						<Form.Group className="mb-3" controlId="titulo">
							<Form.Label>Título</Form.Label>
							<Form.Control type="text" placeholder="Informe aqui" disabled={!formStatus} {...register("title", { required: true })} />
						</Form.Group>
					</Card.Body>
				</Card>

				{id && (
					<Card className="mb-4">
						<Card.Header className="d-flex align-items-center bg-white fs-5 fw-light p-3">Opções</Card.Header>
						<Card.Body>
							{fields.map((domainOption, domainOptionIndex) => {
								return (
									<Row key={domainOption.id} className="mb-2">
										<Col>
											<InputGroup>
												<InputGroup.Text>
													<small className="text-muted">Titulo</small>
												</InputGroup.Text>
												<Form.Control type="text" placeholder="Informe aqui" disabled={!formStatus} {...register(`domainOptions.${domainOptionIndex}.title`)} />
											</InputGroup>
										</Col>
										<Col>
											<InputGroup>
												<InputGroup.Text>
													<small className="text-muted">Valor</small>
												</InputGroup.Text>
												<Form.Control
													type="number"
													placeholder="Informe aqui"
													disabled={!formStatus}
													{...register(`domainOptions.${domainOptionIndex}.value`, { valueAsNumber: true })}
												/>
											</InputGroup>
										</Col>
										<Col lg="auto">
											<Button
												variant="light"
												className="rounded-3 shadow-sm"
												onClick={() => {
													handleRemover(domainOption.id, domainOptionIndex);
												}}
											>
												<Icon path={mdiTrashCanOutline} size={1} className="text-danger" />
											</Button>
										</Col>
									</Row>
								);
							})}
						</Card.Body>
						<Card.Footer className="bg-white">
							<Button variant="light" className="d-flex rounded-3 shadow-sm" onClick={handleAdicionar}>
								<Icon path={mdiPlus} size={1} /> Adicionar Opção
							</Button>
						</Card.Footer>
					</Card>
				)}

				{modulePermissionCheck(user!, ModuleEntity.configuracoesDominios, ModulePermissionEntity.editar) && (
					<div className="d-flex gap-2 mb-4">
						{!formStatus && (
							<Button
								variant="light"
								className="rounded-3 shadow-sm"
								onClick={() => {
									setFormStatus(true);
								}}
							>
								Editar Informações
							</Button>
						)}
						{formStatus && (
							<>
								<Button variant="primary" className="rounded-3 shadow-sm" type="submit" disabled={formSaving}>
									{formSaving ? (
										<>
											<Spinner animation="border" size="sm" className="me-2" /> Salvando
										</>
									) : (
										"Salvar Informações"
									)}
								</Button>
								<Button
									variant="light"
									className="rounded-3 shadow-sm"
									type="button"
									onClick={() => {
										if (id) {
											setFormStatus(false);
										} else {
											navigate("/configuracoesDominios");
										}
									}}
									disabled={formSaving}
								>
									Cancelar
								</Button>
							</>
						)}
					</div>
				)}
			</Form>
		</Layout>
	);
}
