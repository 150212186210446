import { useState } from "react";
import { Button } from "react-bootstrap";

import { RespostaType } from "../../entities/QuestionnaireEntity";
import { QuestionEntity } from "../../entities/QuestionEntity";

type FormObjectiveProps = {
	pergunta: QuestionEntity;
	resposta: RespostaType | null;
	onChange: (value: RespostaType) => void;
};

export default function FormObjective(props: FormObjectiveProps) {
	const [value, setValue] = useState(props.resposta);

	function handleChange(text: string, value: number) {
		let data = { questionId: props.pergunta.id!, text: text, value: value };
		setValue(data);
		props.onChange(data);
	}

	return (
		<div className="d-flex justify-content-center flex-wrap gap-2">
			<Button
				variant="outline-dark"
				size="lg"
				className={`text-uppercase rounded-3 px-5 ${value?.value === 1 ? "active" : ""}`}
				value={1}
				onClick={(e) => {
					handleChange("Sim", Number(e.currentTarget.value));
				}}
				autoFocus={true}
			>
				Sim
			</Button>
			<Button
				variant="outline-dark"
				size="lg"
				className={`text-uppercase rounded-3 px-5 ${value?.value === 0 ? "active" : ""}`}
				value={0}
				onClick={(e) => {
					handleChange("Não", Number(e.currentTarget.value));
				}}
			>
				Não
			</Button>
		</div>
	);
}
