import ReactApexChart from "react-apexcharts";

import { DashboardCardType, DashboardCardTypeApex, DashboardGetCardResponseEntity } from "../../entities/DashboardEntity";
import { Card } from "react-bootstrap";
import { mdiChartBoxOutline } from "@mdi/js";
import Icon from "@mdi/react";

type DashboardChartBarProps = {
	data?: DashboardGetCardResponseEntity;
	loading?: boolean;
	options?: any;
};

export default function DashboardChartBar(props: DashboardChartBarProps) {
	return (
		<Card className="border-0 shadow mb-4">
			<Card.Body className="p-4">
				<div className="d-flex align-items-center justify-content-between">
					<div className={`w-100 fs-5 fw-lighter line-1 ${props.loading ? "skeleton skeleton-text skeleton-text-title" : ""}`}>{props.data?.title ?? "..."}</div>
					<Icon path={mdiChartBoxOutline} size={1} className="float-end" />
				</div>

				{props.loading && (
					<div className="skeleton skeleton-button" style={{ height: 300 }}>
						...
					</div>
				)}
				{!props.loading && props.data && (
					<ReactApexChart
						className="react-apex-chart"
						options={{
							chart: {
								type: DashboardCardTypeApex[props.data.type],
								stacked: props.data.options?.stacked,
								toolbar: {
									show: false,
								},
							},
							xaxis: {
								categories: props.data.categories.map((category, index) => {
									return props.data!.type === DashboardCardType.Radar && index === 0 ? category : category.split(" ");
								}),
							},
							dataLabels: {
								enabled: true,
							},
							plotOptions: {
								bar: {
									borderRadius: 10,
								},
							},
							tooltip: {
								enabled: true,
								shared: true,
								intersect: false,
							},
							...props.options,
						}}
						series={props.data.series}
						type={DashboardCardTypeApex[props.data.type]}
						height={400}
					/>
				)}
			</Card.Body>
		</Card>
	);
}
