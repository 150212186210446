import { useNavigate } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiChevronRight } from "@mdi/js";

import { Unpacked } from "../../config/defines";
import { ClientGetClientsResponse } from "../../entities/ClientEntity";

import ListCard from "../ListCard";

type ClientesCardPropsContent = {
	data: Unpacked<ClientGetClientsResponse>;
	skeleton?: never;
};

type ClientesCardPropsSkeleton = {
	data?: never;
	skeleton: true;
};

type ClientesCardProps = ClientesCardPropsContent | ClientesCardPropsSkeleton;

export default function ClientesCard(props: ClientesCardProps) {
	const navigate = useNavigate();

	function handleClick() {
		if (!props.skeleton && props.data) {
			navigate("/clienteFormulario/" + props.data.id);
		}
	}

	let skeletonButton = "";
	if (props.skeleton) {
		skeletonButton = "skeleton skeleton-button";
	}

	return (
		<>
			<ListCard onClick={handleClick}>
				<ListCard.Content>
					<ListCard.Item>
						<ListCard.Title skeleton={props.skeleton}>Nome</ListCard.Title>
						<ListCard.Text skeleton={props.skeleton}>{props.data?.name ?? "..."}</ListCard.Text>
					</ListCard.Item>
					<ListCard.Item>
						<ListCard.Title skeleton={props.skeleton}>Descrição</ListCard.Title>
						<ListCard.Text skeleton={props.skeleton}>{props.data?.description ?? "..."}</ListCard.Text>
					</ListCard.Item>
				</ListCard.Content>
				<ListCard.Action>
					<Icon path={mdiChevronRight} size={1} className={`text-muted m-2 ${skeletonButton}`} />
				</ListCard.Action>
			</ListCard>
		</>
	);
}
