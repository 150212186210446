import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Card, Col, Form, InputGroup, Modal, Row, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { AxiosError, AxiosResponse } from "axios";
import Icon from "@mdi/react";
import { mdiAccountTieOutline, mdiChevronLeft, mdiPencil, mdiPlus } from "@mdi/js";

import { ModuleEntity, ModulePermissionEntity } from "../../config/defines";
import { modulePermissionCheck } from "../../config/utils";
import { useToast } from "../../context/ToastContext";
import { useAuth } from "../../context/AuthContext";
import { ClientPostRequest, ClientPostResponse, ClientPutRequest, ClientPutResponse } from "../../entities/ClientEntity";
import { UnitPostRequest, UnitPostResponse, UnitPutRequest, UnitPutResponse } from "../../entities/UnitEntity";
import ClientService from "../../services/ClientService";
import UnitService from "../../services/UnitService";

import Layout from "../../components/Layout";

const toastTitle = "Cliente";

export default function ClienteFormulario() {
	const clientService = new ClientService();
	const unitService = new UnitService();

	const navigate = useNavigate();
	const { user } = useAuth();
	const { id } = useParams();
	const { handleToast } = useToast();

	const [formStatus, setFormStatus] = useState(id ? false : true);
	const [formSaving, setFormSaving] = useState(false);

	const [unidadeId, setUnidadeId] = useState<number>();
	const [unidadeModal, setUnidadeModal] = useState(false);
	const [unidadeSaving, setUnidadeSaving] = useState(false);

	const { data, isLoading, isFetching, isRefetching } = useQuery(["cliente", id], () => fetchData(id), { enabled: !!id && !formStatus });

	const queryUnidades = useQuery(["unidades", id], () => fetchDataUnidades(id), { enabled: !!id });

	const { register, handleSubmit, reset } = useForm({ defaultValues: data });

	const formUnidade = useForm();

	const mutationPost = useMutation(mutatePost, { onSuccess: mutatePostSuccess, onError: mutateError });
	const mutationPut = useMutation(mutatePut, { onSuccess: mutatePutSuccess, onError: mutateError });

	const mutationUnidadePost = useMutation(mutateUnidadePost, { onSuccess: mutateUnidadePostSuccess, onError: mutateError });
	const mutationUnidadePut = useMutation(mutateUnidadePut, { onSuccess: mutateUnidadePutSuccess, onError: mutateError });

	async function fetchData(id: any) {
		let resp = await clientService.get(id);
		reset(resp.data);
		return resp.data;
	}

	async function fetchDataUnidades(clientId: any) {
		let resp = await unitService.getUnits(clientId);
		return resp.data;
	}

	async function mutatePost(data: ClientPostRequest) {
		setFormSaving(true);
		return await clientService.post(data);
	}

	function mutatePostSuccess(resp: AxiosResponse<ClientPostResponse, any>) {
		handleToast(toastTitle, "Informações salvas com sucesso!", 5000);
		navigate("/clienteFormulario/" + resp.data.id);
		setFormStatus(false);
		setFormSaving(false);
	}

	async function mutatePut(data: ClientPutRequest) {
		setFormSaving(true);
		return await clientService.put(Number(id), data);
	}

	function mutatePutSuccess(resp: AxiosResponse<ClientPutResponse, any>) {
		handleToast(toastTitle, "Informações salvas com sucesso!", 5000);
		setFormStatus(false);
		setFormSaving(false);
	}

	async function mutateUnidadePost(data: UnitPostRequest) {
		setUnidadeSaving(true);
		return await unitService.post(data);
	}

	function mutateUnidadePostSuccess(resp: AxiosResponse<UnitPostResponse, any>) {
		handleToast(toastTitle, "Informações salvas com sucesso!", 5000);
		queryUnidades.refetch();
		setUnidadeModal(false);
		setUnidadeSaving(false);
	}

	async function mutateUnidadePut(data: UnitPutRequest) {
		setUnidadeSaving(true);
		return await unitService.put(Number(unidadeId), data);
	}

	function mutateUnidadePutSuccess(resp: AxiosResponse<UnitPutResponse, any>) {
		handleToast(toastTitle, "Informações salvas com sucesso!", 5000);
		queryUnidades.refetch();
		setUnidadeModal(false);
		setUnidadeSaving(false);
	}

	function mutateError(resp: AxiosError) {
		handleToast(toastTitle, `Problema na operação: ${resp.message}`, 5000, "danger");
		setFormSaving(false);
		setUnidadeSaving(false);
	}

	return (
		<Layout>
			<Form
				onSubmit={handleSubmit((values) => {
					if (id) {
						mutationPut.mutate({
							name: values.name,
							description: values.description,
						});
					} else {
						mutationPost.mutate({
							name: values.name,
							description: values.description,
						});
					}
				})}
			>
				<h5 className="mt-4 mb-4 d-flex align-items-center fw-light">
					<Link to={"/clientes"} className="d-flex align-items-center text-decoration-none">
						<Icon path={mdiChevronLeft} size={1} className="me-1" /> <Icon path={mdiAccountTieOutline} size={1} className="me-1" /> Formulário de Cliente
					</Link>
					{(isLoading || isFetching || isRefetching) && <Spinner size="sm" className="ms-1" variant="secondary" />}
					<div className="d-flex gap-2 float-right ms-auto" style={{ marginTop: -10, marginBottom: -10 }}>
						{modulePermissionCheck(user!, ModuleEntity.acessos, ModulePermissionEntity.editar) && (
							<>
								{!formStatus && (
									<Button
										variant="light"
										className="rounded-3 shadow-sm"
										onClick={() => {
											setFormStatus(true);
										}}
									>
										Editar Informações
									</Button>
								)}
								{formStatus && (
									<>
										<Button
											variant="light"
											className="rounded-3 shadow-sm"
											type="button"
											onClick={() => {
												if (id) {
													setFormStatus(false);
												} else {
													navigate("/clientes");
												}
											}}
											disabled={formSaving}
										>
											Cancelar
										</Button>
										<Button variant="primary" className="rounded-3 shadow-sm" type="submit" disabled={formSaving}>
											{formSaving ? (
												<>
													<Spinner animation="border" size="sm" className="me-2" /> Salvando
												</>
											) : (
												"Salvar Informações"
											)}
										</Button>
									</>
								)}
							</>
						)}
					</div>
				</h5>

				<Card className="mb-3">
					<Card.Body>
						<Form.Group className="mb-3" controlId="nome">
							<Form.Label>Nome</Form.Label>
							<Form.Control type="text" placeholder="Informe aqui" {...register("name", { required: true })} disabled={!formStatus} />
						</Form.Group>
						<Form.Group className="mb-3" controlId="description">
							<Form.Label>Descrição</Form.Label>
							<Form.Control type="text" placeholder="Informe aqui" {...register("description", { required: true })} disabled={!formStatus} />
						</Form.Group>
					</Card.Body>
				</Card>

				{id && (
					<Card className="mb-4">
						<Card.Header className="d-flex align-items-center bg-white fs-5 fw-light p-3">Unidades</Card.Header>
						<Card.Body>
							{queryUnidades.data?.map((unidade) => {
								return (
									<Row key={unidade.id} className="mb-2">
										<Col>
											<InputGroup>
												<InputGroup.Text>
													<small className="text-muted">Nome</small>
												</InputGroup.Text>
												<Form.Control type="text" placeholder="Informe aqui" value={unidade.name} disabled={true} />
											</InputGroup>
										</Col>
										<Col>
											<InputGroup>
												<InputGroup.Text>
													<small className="text-muted">Descrição</small>
												</InputGroup.Text>
												<Form.Control type="text" placeholder="Informe aqui" value={unidade.description} disabled={true} />
											</InputGroup>
										</Col>
										<Col lg="auto">
											<Button
												variant="light"
												className="rounded-3 shadow-sm"
												onClick={() => {
													setUnidadeId(unidade.id);
													setUnidadeModal(true);
													formUnidade.reset(unidade);
												}}
											>
												<Icon path={mdiPencil} size={1} />
											</Button>
										</Col>
									</Row>
								);
							})}
						</Card.Body>
						<Card.Footer className="bg-white">
							<Button
								variant="light"
								className="d-flex rounded-3 shadow-sm"
								onClick={() => {
									setUnidadeModal(true);
									formUnidade.reset();
								}}
							>
								<Icon path={mdiPlus} size={1} /> Adicionar Unidade
							</Button>
						</Card.Footer>
					</Card>
				)}

				{modulePermissionCheck(user!, ModuleEntity.acessos, ModulePermissionEntity.editar) && (
					<div className="d-flex gap-2 mb-4">
						{!formStatus && (
							<Button
								variant="light"
								className="rounded-3 shadow-sm"
								onClick={() => {
									setFormStatus(true);
								}}
							>
								Editar Informações
							</Button>
						)}
						{formStatus && (
							<>
								<Button variant="primary" className="rounded-3 shadow-sm" type="submit" disabled={formSaving}>
									{formSaving ? (
										<>
											<Spinner animation="border" size="sm" className="me-2" /> Salvando
										</>
									) : (
										"Salvar Informações"
									)}
								</Button>
								<Button
									variant="light"
									className="rounded-3 shadow-sm"
									type="button"
									onClick={() => {
										if (id) {
											setFormStatus(false);
										} else {
											navigate("/clientes");
										}
									}}
									disabled={formSaving}
								>
									Cancelar
								</Button>
							</>
						)}
					</div>
				)}
			</Form>

			<Modal
				show={unidadeModal}
				onHide={() => {
					setUnidadeModal(false);
				}}
				centered
			>
				<Form
					onSubmit={formUnidade.handleSubmit((values) => {
						if (unidadeId) {
							mutationUnidadePut.mutate({
								name: values.name,
								description: values.description,
							});
						} else {
							mutationUnidadePost.mutate({
								name: values.name,
								description: values.description,
								clientId: Number(id),
							});
						}
					})}
				>
					<Modal.Header closeButton>
						<Modal.Title>Adicionar Unidade</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Form.Group className="mb-3" controlId="name">
							<Form.Label>Nome</Form.Label>
							<Form.Control placeholder="Informe aqui" {...formUnidade.register("name", { required: true })} disabled={unidadeSaving} />
						</Form.Group>
						<Form.Group className="mb-3" controlId="description">
							<Form.Label>Descrição</Form.Label>
							<Form.Control placeholder="Informe aqui" {...formUnidade.register("description", { required: true })} disabled={unidadeSaving} />
						</Form.Group>
					</Modal.Body>
					{!unidadeSaving && (
						<Modal.Footer>
							<Button
								variant="secondary"
								onClick={() => {
									setUnidadeModal(false);
								}}
							>
								Cancelar
							</Button>
							<Button variant="primary" type="submit">
								Salvar
							</Button>
						</Modal.Footer>
					)}
				</Form>
			</Modal>
		</Layout>
	);
}
