import { Image } from "react-bootstrap";
import moment from "moment";

export default function Footer() {
	return (
		<div className="d-flex justify-content-center gap-1 p-3 text-muted">
			<div className="d-flex align-items-center text-decoration-none gap-1">
				<Image src={process.env.PUBLIC_URL + "/hyven.png"} style={{ height: 24 }} /> Hyven
			</div>{" "}
			Copyright© {moment().format("YYYY")}. Todos os direitos reservados.
		</div>
	);
}
