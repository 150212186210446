import { QuestionGroupEntity } from "./QuestionGroupEntity";
import { QuestionnaireResponseQuestionResponses } from "./QuestionnaireResponseEntity";

// QUESTIONNAIRE
export type QuestionnaireEntity = {
	id: number;
	title: string;
	purpose: string;
	questionGroups: QuestionGroupEntity[];
	isEditable?: boolean;
};

export type QuestionnaireGetQuestionnairesResponse = Omit<QuestionnaireEntity, "questionGroups">;

export type QuestionnaireGetResponse = QuestionnaireEntity;

export type QuestionnairePostRequest = Omit<QuestionnaireEntity, "id">;

export type QuestionnairePostResponse = QuestionnaireEntity;

export type QuestionnairePutRequest = {
	title: string;
	purpose: string;
};

export type QuestionnairePutResponse = {
	message: string;
};

export type QuestionnaireDeleteResponse = {
	message: string;
};

// OUTROS
export enum PerguntaTipo {
	text = 1,
	textarea = 2,
	number = 3,
	radio = 4,
	checkbox = 5,
	select = 6,
	file = 7,
	image = 8,
	date = 9,
	objective = 10,
	geo = 11,
	parametro = 12,
}

export type PerguntaType = {
	id: number;
	titulo: string;
	texto: string;
	tipo: PerguntaTipo;
	multiple?: boolean;
	opcoes?: PerguntaOpcaoType[][];
	dependencia?: number[][];
	parametroId?: number;
};

export type PerguntaOpcaoType = {
	id: number | null;
	titulo: string;
	valor: string;
	informacoes?: string;
	dominioOptionId?: number;
};

export type RespostaType = {
	index?: number;
	perguntaId?: any;
	opcaoId?: any;
	titulo?: string;
	valor?: any;
	contentType?: string;
	lat?: number;
	lng?: number;
	dominioOptionId?: number;
} & QuestionnaireResponseQuestionResponses;
