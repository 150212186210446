import ApiService from "./ApiService";
import {
	ParameterTypeDeleteResponse,
	ParameterTypeGetParameterTypesResponse,
	ParameterTypePostRequest,
	ParameterTypePostResponse,
	ParameterTypePutRequest,
	ParameterTypePutResponse,
} from "../entities/ParameterTypeEntity";

export default class ParameterTypeService extends ApiService {
	public async getParameterTypes() {
		return await this.request.get<ParameterTypeGetParameterTypesResponse>(`/api/v1/ParameterTypes`);
	}
	public async post(data: ParameterTypePostRequest) {
		return await this.request.post<ParameterTypePostResponse>(`/api/v1/ParameterType`, data);
	}
	public async put(id: number, data: ParameterTypePutRequest) {
		return await this.request.put<ParameterTypePutResponse>(`/api/v1/ParameterType/${id}`, data);
	}
	public async delete(id: number) {
		return await this.request.delete<ParameterTypeDeleteResponse>(`/api/v1/ParameterType/${id}`);
	}
}
