import ReactApexChart from "react-apexcharts";
import { Card } from "react-bootstrap";
import Icon from "@mdi/react";
import { mdiChartBoxOutline } from "@mdi/js";

import { DashboardCardTypeApex, DashboardGetCardResponseEntity } from "../../entities/DashboardEntity";

type DashboardChartRadialProps = {
	data?: DashboardGetCardResponseEntity;
	loading?: boolean;
	options?: any;
};

export default function DashboardChartRadial(props: DashboardChartRadialProps) {
	return (
		<Card className="border-0 shadow mb-4">
			<Card.Body className="p-4">
				<div className="d-flex align-items-center justify-content-between">
					<div className={`w-100 fs-5 fw-lighter line-1 ${props.loading ? "skeleton skeleton-text skeleton-text-title" : ""}`}>{props.data?.title ?? "..."}</div>
					<Icon path={mdiChartBoxOutline} size={1} className="float-end" />
				</div>

				{props.loading && (
					<div className="skeleton skeleton-button" style={{ height: 300 }}>
						...
					</div>
				)}
				{!props.loading && props.data && (
					<ReactApexChart
						className="react-apex-chart"
						options={{
							chart: {
								type: DashboardCardTypeApex[props.data.type],
								stacked: props.data.options?.stacked,
								toolbar: {
									show: false,
								},
							},
							...props.options,
						}}
						series={props.data.series[0].data}
						type={DashboardCardTypeApex[props.data.type]}
						height={300}
					/>
				)}
			</Card.Body>
		</Card>
	);
}
