import { Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiChevronRight } from "@mdi/js";

import { NavigationEntity } from "../../config/defines";
import { DashboardGetCardResponseEntity } from "../../entities/DashboardEntity";

type DashboardNumberCardProps = {
	data?: DashboardGetCardResponseEntity;
	loading?: boolean;
	options?: any;
};

export default function DashboardNumberCard(props: DashboardNumberCardProps) {
	const navigate = useNavigate();

	return (
		<Card
			className="border-0 shadow mb-4"
			onClick={() => {
				if (props.data?.navigationId) {
					navigate(NavigationEntity[props.data.navigationId]);
				}
			}}
			style={{
				cursor: props.data?.navigationId ? "pointer" : "",
			}}
		>
			<Card.Body className="d-flex flex-row justify-content-between gap-2 p-4">
				<div className="d-flex flex-column flex-fill">
					<div className={`w-100 d-flex align-items-center line-1 small ${props.loading ? "skeleton skeleton-text skeleton-text-title" : ""}`}>{props.data?.title ?? "..."}</div>
					<div className={`d-flex align-items-center ${props.loading ? "skeleton skeleton-text skeleton-text-title" : ""}`} style={{ minHeight: 60 }}>
						<div className="fw-bold fs-1">{props.data?.value}</div>
						{/* {props.valueDescription} */}
					</div>
					{/* {!props.loading && props.subtitle && <div className={`d-flex align-items-center line-1 fw-light text-muted small`}>{props.subtitle}</div>} */}
				</div>
				<div className={`d-flex flex-row align-items-center justify-content-center ${props.loading ? "skeleton skeleton-text" : ""}`}>
					<Icon path={props.options?.icon} style={{ width: 60 }} />
					{props.data?.navigationId && <Icon path={mdiChevronRight} size={1} className={`text-muted`} style={{ marginRight: -12 }} />}
				</div>
			</Card.Body>
		</Card>
	);
}
