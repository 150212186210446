import ApiService from "./ApiService";
import { CompanyGetCompaniesResponseEntity, CompanyGetResponseEntity, CompanyPostRequest, CompanyPostResponse, CompanyPutRequest, CompanyPutResponse } from "../entities/CompanyEntity";

export default class CompanyService extends ApiService {
	public async getCompanies() {
		return await this.request.get<CompanyGetCompaniesResponseEntity>(`/api/v1/Companies`);
	}
	public async get(id: number) {
		return await this.request.get<CompanyGetResponseEntity>(`/api/v1/Company/${id}`);
	}
	public async post(data: CompanyPostRequest) {
		return await this.request.post<CompanyPostResponse>(`/api/v1/Company`, data);
	}
	public async put(id: number, data: CompanyPutRequest) {
		return await this.request.put<CompanyPutResponse>(`/api/v1/Company/${id}`, data);
	}
}
