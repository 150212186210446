import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { Alert, Button, Col, Modal, Row, Spinner } from "react-bootstrap";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { AxiosError, AxiosResponse } from "axios";
import Icon from "@mdi/react";
import { mdiCardRemoveOutline, mdiChevronRight, mdiCogOutline, mdiTune } from "@mdi/js";

import { ModuleEntity, ModulePermissionEntity, SkeletonCount } from "../../config/defines";
import { modulePermissionCheck } from "../../config/utils";
import { useToast } from "../../context/ToastContext";
import { useAuth } from "../../context/AuthContext";
import { ParameterEntity, ParameterGetParametersResponse, ParameterPutResponse } from "../../entities/ParameterEntity";
import ParameterService from "../../services/ParameterService";

import ParametrosCard from "../../components/ParametrosCard";
import Layout from "../../components/Layout";

const toastTitle = "Parâmetros";

export default function ConfiguracoesParametros() {
	const parameterService = new ParameterService();

	const navigate = useNavigate();
	const { user } = useAuth();
	const { handleToast } = useToast(toastTitle);

	const [formOrderModal, setFormOrderModal] = useState(false);
	const [formOrderSaving, setFormOrderSaving] = useState(false);
	const [formOrderData, setFormOrderData] = useState<ParameterGetParametersResponse>();

	const { data, isLoading, isRefetching, isError, refetch } = useQuery<ParameterGetParametersResponse>(["parametros"], () => fetchData());

	const mutationPut = useMutation(mutatePut, { onSuccess: mutatePutSuccess, onError: mutateError });

	async function fetchData() {
		const resp = await parameterService.getParameters();
		return resp.data.sort((a, b) => {
			return a.order - b.order;
		});
	}

	async function mutatePut(data: ParameterEntity[]) {
		setFormOrderSaving(true);

		let resp;
		for (let i in data) {
			let item = data[Number(i)];
			item.order = Number(i);
			resp = await parameterService.put(item.id!, {
				title: item.title,
				order: item.order,
				technicalImportance: item.technicalImportance,
				functionalImportance: item.functionalImportance,
				maxValue: item.maxValue,
				typeId: item.type.id,
			});
		}

		return resp!;
	}

	function mutatePutSuccess(resp: AxiosResponse<ParameterPutResponse, any>) {
		handleToast(toastTitle, "Informações salvas com sucesso!", 5000);
		setFormOrderSaving(false);
		setFormOrderModal(false);
		refetch();
	}

	function mutateError(resp: AxiosError) {
		handleToast(toastTitle, `Problema na operação: ${resp.message}`, 5000, "danger");
		setFormOrderSaving(false);
	}

	async function handleOrderSalvar() {
		mutationPut.mutate(formOrderData!);
	}

	return (
		<Layout>
			<h5 className="mt-4 mb-4 d-flex align-items-center fw-light">
				<Link to={"/configuracoes"} className="d-flex text-decoration-none">
					<Icon path={mdiCogOutline} size={1} className="me-1" /> Configurações
				</Link>
				<Icon path={mdiChevronRight} size={1} className="ms-1 me-1" />
				<Icon path={mdiTune} size={1} className="me-1" /> Parâmetros
				{isRefetching && <Spinner size="sm" className="ms-1" variant="secondary" />}
				<div className="d-flex gap-2 float-right ms-auto" style={{ marginTop: -10, marginBottom: -10 }}>
					{modulePermissionCheck(user!, ModuleEntity.configuracoesParametros, ModulePermissionEntity.editar) && (
						<>
							<Button
								variant="light"
								className="rounded-3 shadow-sm"
								onClick={() => {
									setFormOrderData(data);
									setFormOrderModal(true);
								}}
							>
								Ordenar
							</Button>
							<Button
								variant="primary"
								className="rounded-3 shadow-sm"
								onClick={() => {
									navigate("/parametroFormulario");
								}}
							>
								Cadastrar
							</Button>
						</>
					)}
				</div>
			</h5>

			<Row className="mb-4">
				{isLoading &&
					Array.from({ length: Number(SkeletonCount) }, (_, index) => {
						return (
							<Col md={12} key={index}>
								<ParametrosCard skeleton />
							</Col>
						);
					})}
				{!isLoading && isError && (
					<Col md={12}>
						<Alert variant="secondary" className="text-center">
							Problema na operação. Tente novamente mais tarde
						</Alert>
					</Col>
				)}
				{!isLoading && data && data.length === 0 && (
					<Col md={12}>
						<Alert variant="light" className="text-center p-4 d-flex justify-content-center align-items-center">
							<Icon path={mdiCardRemoveOutline} size={1} className="me-2" />
							Nenhum registro encontrado
						</Alert>
					</Col>
				)}
				{!isLoading &&
					data &&
					data.length >= 1 &&
					data.map((item, index) => {
						return (
							<Col md={12} key={item.id}>
								<ParametrosCard data={item} />
							</Col>
						);
					})}
			</Row>

			{formOrderData !== undefined && (
				<Modal
					show={formOrderModal}
					onHide={() => {
						setFormOrderModal(false);
					}}
					centered
					size="lg"
				>
					<Modal.Header closeButton>
						<Modal.Title className="d-flex flex-column lh-1">Ordenar Parâmetros</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						{formOrderSaving && (
							<div className="d-flex justify-content-center align-items-center gap-2 p-2">
								<Spinner /> Salvando...
							</div>
						)}
						{!formOrderSaving && (
							<SortableList
								items={formOrderData.map((item) => {
									return { id: item.id!, text: item.title };
								})}
								onSortEnd={(values) => {
									let orderData = [...formOrderData];
									let [item] = orderData.splice(values.oldIndex, 1);
									orderData.splice(values.newIndex, 0, item);
									setFormOrderData(orderData);
								}}
								lockAxis="y"
								helperClass="sortable-item--dragging"
							/>
						)}
					</Modal.Body>
					{!formOrderSaving && (
						<Modal.Footer>
							<Button
								variant="secondary"
								onClick={() => {
									setFormOrderModal(false);
								}}
							>
								Cancelar
							</Button>
							<Button
								onClick={() => {
									handleOrderSalvar();
								}}
							>
								Salvar
							</Button>
						</Modal.Footer>
					)}
				</Modal>
			)}
		</Layout>
	);
}

interface Item {
	id: number;
	text: string;
}

interface SortableListProps {
	items: Item[];
	onSortEnd: ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => void;
}

const SortableItem = SortableElement<{ item: Item }>(({ item }: any) => {
	return (
		<li className={`d-flex align-items-center border-bottom p-2 sortable-item list-unstyled`} style={{ zIndex: 1060, cursor: "ns-resize" }}>
			{item.text}
		</li>
	);
});

const SortableList = SortableContainer<SortableListProps>(({ items }: any) => {
	return (
		<ul className="border rounded-3 list-unstyled user-select-none" style={{ cursor: "ns-resize" }}>
			{items.map((value: any, index: any) => (
				<SortableItem key={`item-${index}`} index={index} item={value} />
			))}
		</ul>
	);
});
