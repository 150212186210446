import ApiService from "./ApiService";
import {
	UserGetRecoveryTokenRequestEntity,
	UserGetRecoveryTokenResponseEntity,
	UserGetTokenRefreshResponseEntity,
	UserLoginPostRequestEntity,
	UserLoginPostResponseEntity,
	UserUpdatePasswordRequestEntity,
	UserUpdatePasswordResponseEntity,
	UserValidateRecoveryTokenRequestEntity,
	UserValidateRecoveryTokenResponseEntity,
} from "../entities/UserEntity";

export default class UserService extends ApiService {
	public async login(data: UserLoginPostRequestEntity) {
		return await this.request.post<UserLoginPostResponseEntity>(`/api/v1/Login`, data, { headers: { Authorization: undefined } });
	}

	public async getTokenRefresh() {
		return await this.request.get<UserGetTokenRefreshResponseEntity>(`/api/v1/TokenRefresh`);
	}

	public async getRecoveryToken(data: UserGetRecoveryTokenRequestEntity) {
		return await this.request.get<UserGetRecoveryTokenResponseEntity>(`/api/v1/GetRecoveryToken`, { params: data });
	}

	public async validateRecoveryToken(data: UserValidateRecoveryTokenRequestEntity) {
		return await this.request.get<UserValidateRecoveryTokenResponseEntity>(`/api/v1/ValidateRecoveryToken`, { params: data });
	}

	public async updatePassword(data: UserUpdatePasswordRequestEntity) {
		return await this.request.post<UserUpdatePasswordResponseEntity>("/api/v1/UpdatePassword", data);
	}
}
