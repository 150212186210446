import { mdiAccount, mdiBookCogOutline, mdiBookOutline } from "@mdi/js";

export enum DashboardCardType {
	Radar = 1,
	Bar = 2,
	RadialBar = 3,
	Value = 4,
}

export const DashboardCardTypeApex: {
	[key: number]:
		| "radar"
		| "bar"
		| "donut"
		| "radialBar"
		| "line"
		| "area"
		| "pie"
		| "scatter"
		| "bubble"
		| "heatmap"
		| "candlestick"
		| "boxPlot"
		| "polarArea"
		| "rangeBar"
		| "rangeArea"
		| "treemap"
		| undefined;
} = {
	1: "radar",
	2: "bar",
	3: "radialBar",
	// "0": "area",
	// "0": "line",
	// "0": "pie",
	// "0": "scatter",
	// "0": "bubble",
	// "0": "heatmap",
	// "0": "candlestick",
	// "0": "boxPlot",
	// "0": "polarArea",
	// "0": "rangeBar",
	// "0": "rangeArea",
	// "0": "treemap",
};

export enum DashboardCardSize {
	Small = 1,
	Medium = 2,
	Large = 3,
}

export const DashboardCardSizeApex = {
	1: 4,
	2: 6,
	3: 12,
};

export const DashboardCardExtra: { [key: number]: any } = {
	4: {
		yaxis: {
			labels: {
				formatter: (value: any) => `${value}%`,
			},
		},
		dataLabels: {
			formatter: (value: any) => `${value}%`,
		},
		legend: {
			position: "right",
		},
	},
	5: {
		yaxis: {
			labels: {
				formatter: (value: any) => `${value}%`,
			},
		},
		dataLabels: {
			formatter: (value: any) => `${value}%`,
		},
		legend: {
			position: "right",
		},
	},
	6: {
		yaxis: {
			labels: {
				formatter: (value: any) => `${value}%`,
			},
		},
		dataLabels: {
			formatter: (value: any) => `${value}%`,
		},
		legend: {
			position: "right",
		},
	},
	7: {
		yaxis: {
			title: { text: "Índice de Danos Técnicos" },
			max: 5,
			min: 0,
		},
		dataLabels: {
			offsetY: -20,
			style: {
				colors: ["#304758"],
			},
		},
		plotOptions: {
			bar: {
				borderRadius: 10,
				dataLabels: {
					position: "top",
				},
			},
		},
	},
	8: {
		yaxis: {
			title: { text: "Índice de Danos Funcionais" },
			max: 5,
			min: 0,
		},
		dataLabels: {
			offsetY: -20,
			style: {
				colors: ["#304758"],
			},
		},
		plotOptions: {
			bar: {
				borderRadius: 10,
				dataLabels: {
					position: "top",
				},
			},
		},
	},
	9: {
		yaxis: {
			title: { text: "Índice de Danos Técnicos" },
			max: 5,
			min: 0,
		},
		dataLabels: {
			offsetY: -20,
			style: {
				colors: ["#304758"],
			},
		},
		plotOptions: {
			bar: {
				borderRadius: 10,
				dataLabels: {
					position: "top",
				},
			},
		},
	},
	10: {
		yaxis: {
			title: { text: "Índice de Danos Funcionais" },
			max: 5,
			min: 0,
		},
		dataLabels: {
			offsetY: -20,
			style: {
				colors: ["#304758"],
			},
		},
		plotOptions: {
			bar: {
				borderRadius: 10,
				dataLabels: {
					position: "top",
				},
			},
		},
	},
	11: {
		labels: ["Danos estruturais"],
	},
	12: {
		labels: ["Danos estruturais"],
	},
	13: {
		labels: ["Danos estruturais"],
	},
	14: {
		icon: mdiAccount,
	},
	15: {
		icon: mdiBookOutline,
	},
	16: {
		icon: mdiBookCogOutline,
	},
};

export type DashboardCardsEntity = {
	id: number;
	type: DashboardCardType;
	size: DashboardCardSize;
};

export type DashboardCardEntity = {
	title: string;
	type: DashboardCardType;
	size: DashboardCardSize;
	series: {
		name: string;
		data: number[];
	}[];
	categories: string[];
	options: {
		stacked: true;
	};
	yaxis: {
		title: string;
	};
	value: number;
	navigationId: number;
};

export type DashboardGetResponseEntity = {
	cards: DashboardCardsEntity[];
};

export type DashboardGetCardResponseEntity = DashboardCardEntity;
