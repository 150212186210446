import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiMagnifyRemoveOutline } from "@mdi/js";

export default function Page404() {
	const navigate = useNavigate();

	return (
		<Container className="d-flex justify-content-center flex-column p-4 pb-0" style={{ minHeight: "100vh" }}>
			<Row>
				<Col lg={{ span: 6, offset: 3 }}>
					<Card className="p-3 shadow-lg bg-white rounded-3">
						<Card.Body className="text-center">
							<Image fluid className="pb-4" src={process.env.PUBLIC_URL + "/logo.webp"} style={{ width: "50%" }} />

							<Card.Title className="text-center mb-4 fs-2">
								<Icon path={mdiMagnifyRemoveOutline} className="me-1" size={5} />
							</Card.Title>
							<Card.Title className="mb-2 fs-2">Página não encontrada</Card.Title>
							<Card.Text className="mb-5 fw-light">A página que você tentou acessar não foi encontrada</Card.Text>
							<div className="d-flex justify-content-center gap-2">
								<Button
									variant="outline-primary"
									onClick={() => {
										navigate("/");
									}}
									style={{ width: 160 }}
								>
									Ir para o início
								</Button>
								<Button
									variant="outline-primary"
									onClick={() => {
										navigate(-1);
									}}
									style={{ width: 160 }}
								>
									Voltar
								</Button>
							</div>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Container>
	);
}
