import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Card, Col, Form, InputGroup, ListGroup, Modal, Row, Spinner } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import { Control, useFieldArray, useForm, UseFormGetValues, UseFormRegister } from "react-hook-form";
import { AxiosError, AxiosResponse } from "axios";
import Select from "react-select";
import Icon from "@mdi/react";
import { mdiCheck, mdiChevronLeft, mdiClose, mdiPencil, mdiPlus, mdiTrashCanOutline, mdiTune } from "@mdi/js";

import { ModuleEntity, ModulePermissionEntity, Unpacked } from "../../config/defines";
import { SelectGroupStyle, SelectOptions } from "../../config/select";
import { modulePermissionCheck } from "../../config/utils";
import { ParameterEntity, ParameterGetResponse, ParameterPostRequest, ParameterPostResponse, ParameterPutResponse } from "../../entities/ParameterEntity";
import { ParameterDomainDeleteResponse, ParameterDomainPostResponse } from "../../entities/ParameterDomainEntity";
import { DomainEntity, DomainEntityGetResponse } from "../../entities/DomainEntity";
import { useToast } from "../../context/ToastContext";
import { useAuth } from "../../context/AuthContext";
import ParameterDomainOptionService from "../../services/ParameterDomainOptionService";
import ParameterDomainService from "../../services/ParameterDomainService";
import ParameterTypeService from "../../services/ParameterTypeService";
import ParameterService from "../../services/ParameterService";
import DomainService from "../../services/DomainService";

import Layout from "../../components/Layout";
import { ParameterTypeGetParameterTypesResponse } from "../../entities/ParameterTypeEntity";

const toastTitle = "Parâmetro";

export default function ParametroFormulario() {
	const parameterService = new ParameterService();
	const parameterDomainService = new ParameterDomainService();
	const parameterDomainOptionService = new ParameterDomainOptionService();
	const parameterTypeService = new ParameterTypeService();
	const domainService = new DomainService();

	const navigate = useNavigate();
	const { user } = useAuth();
	const { id } = useParams();
	const { handleToast } = useToast();

	const [formStatus, setFormStatus] = useState(id ? false : true);
	const [formSaving, setFormSaving] = useState(false);
	const [formModal, setFormModal] = useState(false);

	const [tipoModal, setTipoModal] = useState(false);
	const [tipoLoadingModal, setTipoLoadingModal] = useState(false);
	const [tipoTituloModal, setTipoTituloModal] = useState("");
	const [tipoIdModal, setTipoIdModal] = useState<number>();
	const [tiposModal, setTiposModal] = useState<ParameterTypeGetParameterTypesResponse>();

	const queryDominios = useQuery<DomainEntityGetResponse[]>(["dominios"], () => fetchDataDominios());
	const queryTipos = useQuery(["parametroTipos"], () => fetchDataTipos());

	const { data, isLoading, isFetching, isRefetching } = useQuery<ParameterGetResponse>(["parametro", id], () => fetchData(id), { enabled: !!id && !formStatus });

	const mutationPost = useMutation(mutatePost, { onSuccess: mutatePostSuccess, onError: mutateError });
	const mutationPut = useMutation(mutatePut, { onSuccess: mutatePutSuccess, onError: mutateError });
	const mutationDomainPost = useMutation(mutateDomainPost, { onSuccess: mutateDomainPostSuccess, onError: mutateError });
	const mutationDomainDelete = useMutation(mutateDomainDelete, { onSuccess: mutateDomainDeleteSuccess, onError: mutateError });

	const { register, control, handleSubmit, setValue, getValues, reset } = useForm({ defaultValues: data });
	const { fields, append, remove } = useFieldArray({ name: "parameterDomains", control: control, keyName: "key" });

	async function fetchDataDominios() {
		const resp = await domainService.getDomains();
		return resp.data;
	}

	async function fetchDataTipos() {
		const resp = await parameterTypeService.getParameterTypes();
		setTiposModal(resp.data);
		return resp.data;
	}

	async function fetchData(id: any) {
		let resp = await parameterService.get(id);
		reset(resp.data);
		return resp.data;
	}

	async function mutatePost(data: ParameterPostRequest) {
		setFormSaving(true);
		return await parameterService.post(data);
	}

	function mutatePostSuccess(resp: AxiosResponse<ParameterPostResponse, any>) {
		handleToast(toastTitle, "Informações salvas com sucesso!", 5000);
		navigate("/parametroFormulario/" + resp.data.id);
		setFormStatus(false);
		setFormSaving(false);
	}

	async function mutatePut(data: ParameterEntity) {
		setFormSaving(true);

		// PARAMETER
		let resp = await parameterService.put(Number(id), {
			title: data.title,
			order: data.order,
			technicalImportance: data.technicalImportance,
			functionalImportance: data.functionalImportance,
			maxValue: data.maxValue,
			typeId: data.type.id,
		});

		// DOMAIN
		for (let i in data.parameterDomains) {
			let parameterDomain = data.parameterDomains[Number(i)];
			resp = await parameterDomainService.put(parameterDomain.id!, {
				required: parameterDomain.required,
			});

			// OPTIONS
			for (let j in parameterDomain.parameterDomainOptions) {
				let parameterDomainOption = parameterDomain.parameterDomainOptions[Number(j)];
				resp = await parameterDomainOptionService.put(parameterDomainOption.id!, {
					info: parameterDomainOption.info,
					enabled: parameterDomainOption.enabled,
				});
			}
		}

		return resp!;
	}

	function mutatePutSuccess(resp: AxiosResponse<ParameterPutResponse, any>) {
		handleToast(toastTitle, "Informações salvas com sucesso!", 5000);
		setFormStatus(false);
		setFormSaving(false);
	}

	async function mutateDomainPost(data: DomainEntity) {
		setFormSaving(true);

		// DOMAIN
		let resp = await parameterDomainService.post(Number(id), { domainId: Number(data.id), required: true });

		// OPTIONS
		for (let i in data.domainOptions) {
			let item = data.domainOptions[Number(i)];
			let respOption = await parameterDomainOptionService.post(resp.data.id!, {
				domainOptionId: item.id!,
				info: "",
				enabled: true,
			});

			resp.data.parameterDomainOptions.push(respOption.data);
		}

		return resp;
	}

	function mutateDomainPostSuccess(resp: AxiosResponse<ParameterDomainPostResponse, any>) {
		append(resp.data);
		handleToast(toastTitle, "Informações salvas com sucesso!", 5000);
		setFormSaving(false);
		setFormModal(false);
	}

	async function mutateDomainDelete({ id, index }: { id: number; index: number }) {
		setFormSaving(true);
		let resp = await parameterDomainService.delete(id);
		return { index: index, resp: resp };
	}

	function mutateDomainDeleteSuccess({ index, resp }: { index: number; resp: AxiosResponse<ParameterDomainDeleteResponse, any> }) {
		remove(index);
		handleToast(toastTitle, "Informações salvas com sucesso!", 5000);
		setFormSaving(false);
	}

	function mutateError(resp: AxiosError) {
		handleToast(toastTitle, `Problema na operação: ${resp.message}`, 5000, "danger");
		setFormSaving(false);
	}

	function handleAdicionar(values: DomainEntity) {
		mutationDomainPost.mutate(values);
	}

	function handleRemover(id: number, index: number) {
		mutationDomainDelete.mutate({ id, index });
	}

	async function handleTipo(value: any) {
		setValue(`type.id`, value ? value.value : undefined);
	}

	async function handleTipoSalvar() {
		setTipoLoadingModal(true);
		let resp = await parameterTypeService.post({ title: tipoTituloModal });
		if (resp.status === 201) {
			setTipoTituloModal("");
			queryTipos.refetch();
		} else {
			handleToast(toastTitle, resp.statusText, 5000, "danger");
		}
		setTipoLoadingModal(false);
	}

	async function handleTipoEditar(tipo: Unpacked<ParameterTypeGetParameterTypesResponse>) {
		setTipoLoadingModal(true);
		let resp = await parameterTypeService.put(Number(tipo.id), { title: tipo.title });
		if (resp.status === 200) {
			queryTipos.refetch();
		} else {
			handleToast(toastTitle, resp.statusText, 5000, "danger");
		}
		setTipoIdModal(undefined);
		setTipoLoadingModal(false);
	}

	async function handleTipoRemover(id: number) {
		setTipoLoadingModal(true);
		let resp = await parameterTypeService.delete(id);
		if (resp.status === 200) {
			queryTipos.refetch();
		} else {
			handleToast(toastTitle, resp.statusText, 5000, "danger");
		}
		setTipoLoadingModal(false);
	}

	const formTiposOptions = queryTipos.data?.map((item) => {
		return { label: `${item.title}`, value: item.id };
	});

	return (
		<Layout>
			<Form
				onSubmit={handleSubmit((values) => {
					if (id) {
						mutationPut.mutate(values);
					} else {
						mutationPost.mutate({
							title: values.title,
							order: values.order,
							technicalImportance: values.technicalImportance,
							functionalImportance: values.functionalImportance,
							maxValue: values.maxValue,
							typeId: values.type.id,
							parameterDomains: [],
						});
					}
				})}
			>
				<h5 className="mt-4 mb-4 d-flex align-items-center fw-light">
					<Link to={"/configuracoesParametros"} className="d-flex align-items-center text-decoration-none">
						<Icon path={mdiChevronLeft} size={1} className="me-1" /> <Icon path={mdiTune} size={1} className="me-1" /> Formulário de Parâmetro
					</Link>
					{(isLoading || isFetching || isRefetching) && <Spinner size="sm" className="ms-1" variant="secondary" />}
					<div className="d-flex gap-2 float-right ms-auto" style={{ marginTop: -10, marginBottom: -10 }}>
						{modulePermissionCheck(user!, ModuleEntity.configuracoesParametros, ModulePermissionEntity.editar) && (
							<>
								{!formStatus && (
									<Button
										variant="light"
										className="rounded-3 shadow-sm"
										onClick={() => {
											setFormStatus(true);
										}}
									>
										Editar Informações
									</Button>
								)}
								{formStatus && (
									<>
										<Button
											variant="light"
											className="rounded-3 shadow-sm"
											type="button"
											onClick={() => {
												if (id) {
													setFormStatus(false);
												} else {
													navigate("/parametros");
												}
											}}
											disabled={formSaving}
										>
											Cancelar
										</Button>
										<Button variant="primary" className="rounded-3 shadow-sm" type="submit" disabled={formSaving}>
											{formSaving ? (
												<>
													<Spinner animation="border" size="sm" className="me-2" /> Salvando
												</>
											) : (
												"Salvar Informações"
											)}
										</Button>
									</>
								)}
							</>
						)}
					</div>
				</h5>

				<Card className="mb-4">
					<Card.Header className="d-flex align-items-center bg-white fs-5 fw-light p-3">Parâmetro</Card.Header>
					<Card.Body className="row">
						<Form.Group className="mb-3 col-md-6" controlId="titulo">
							<Form.Label>Título</Form.Label>
							<Form.Control type="text" placeholder="Informe aqui" {...register("title", { required: true })} disabled={!formStatus} />
						</Form.Group>
						<Form.Group className="mb-3 col-md-6" controlId={`type`}>
							<Form.Label>Tipo</Form.Label>
							<InputGroup>
								{(!queryTipos.isFetched || isLoading) && (
									<div className="form-control disabled">
										<Spinner size="sm" />
									</div>
								)}
								{queryTipos.isFetched && !isLoading && (
									<Select
										placeholder={"Selecione"}
										defaultValue={formTiposOptions?.find((option) => {
											return option.value === data?.type?.id;
										})}
										options={formTiposOptions}
										onChange={(value) => {
											handleTipo(value);
										}}
										styles={SelectGroupStyle}
										isDisabled={!formStatus}
										{...SelectOptions}
									/>
								)}
								<Button
									variant="light"
									className="border"
									onClick={() => {
										setTipoModal(true);
									}}
								>
									<Icon path={mdiPencil} size={1} />
								</Button>
							</InputGroup>
						</Form.Group>
						<Form.Group className="mb-3 col-md-6" controlId="importanciaTecnica">
							<Form.Label>Importância Técnica</Form.Label>
							<Form.Control type="number" step={"any"} placeholder="Informe aqui" {...register("technicalImportance", { valueAsNumber: true })} disabled={!formStatus} />
						</Form.Group>
						<Form.Group className="mb-3 col-md-6" controlId="importanciaFuncional">
							<Form.Label>Importância Funcional</Form.Label>
							<Form.Control type="number" step={"any"} placeholder="Informe aqui" {...register("functionalImportance", { valueAsNumber: true })} disabled={!formStatus} />
						</Form.Group>
						<Form.Group className="mb-3" controlId="valorMaximo">
							<Form.Label>Valor Máximo</Form.Label>
							<Form.Control type="number" step={"any"} placeholder="Informe aqui" {...register("maxValue", { valueAsNumber: true })} disabled={!formStatus} />
						</Form.Group>
					</Card.Body>
				</Card>

				{id && (
					<>
						{fields.map((parameterDomain, parameterDomainIndex) => {
							let index = Math.random();
							return (
								<Card key={index} className="mb-4">
									<Card.Header className="d-flex align-items-center bg-white fs-5 fw-light p-3">
										<div className="d-flex flex-column lh-1">
											<div className="fs-6">
												<small className="text-muted fw-light">Domínio</small>
											</div>
											{parameterDomain.domain.title}
										</div>
										<div className="float-right ms-auto">
											<Button
												type="button"
												variant="light"
												className="d-flex rounded-3 shadow-sm"
												onClick={() => {
													handleRemover(parameterDomain.id!, parameterDomainIndex);
												}}
											>
												<Icon path={mdiTrashCanOutline} size={1} className="text-danger" />
											</Button>
										</div>
									</Card.Header>
									<Card.Body>
										<Form.Group className="mb-3 col-md-3" controlId={`parameterDomains.${parameterDomainIndex}.required`}>
											<Form.Label>Obrigatório</Form.Label>
											<Form.Check type="switch" className="pt-1" {...register(`parameterDomains.${parameterDomainIndex}.required`)} disabled={!formStatus} />
										</Form.Group>
										<FormOpcoes formStatus={formStatus} control={control} register={register} getValues={getValues} parentFieldIndex={parameterDomainIndex} />
									</Card.Body>
								</Card>
							);
						})}

						<div className="d-flex mb-4">
							<Button
								variant="light"
								className="d-flex gap-2 align-items-center rounded-3 shadow-sm"
								onClick={() => {
									setFormModal(true);
								}}
							>
								<Icon path={mdiPlus} size={1} /> Adicionar Domínio
							</Button>
						</div>
					</>
				)}

				{modulePermissionCheck(user!, ModuleEntity.configuracoesParametros, ModulePermissionEntity.editar) && (
					<div className="d-flex gap-2 mb-4">
						{!formStatus && (
							<Button
								variant="light"
								className="rounded-3 shadow-sm"
								onClick={() => {
									setFormStatus(true);
								}}
							>
								Editar Informações
							</Button>
						)}
						{formStatus && (
							<>
								<Button variant="primary" className="rounded-3 shadow-sm" type="submit" disabled={formSaving}>
									{formSaving ? (
										<>
											<Spinner animation="border" size="sm" className="me-2" /> Salvando
										</>
									) : (
										"Salvar Informações"
									)}
								</Button>
								<Button
									variant="light"
									className="rounded-3 shadow-sm"
									type="button"
									onClick={() => {
										if (id) {
											setFormStatus(false);
										} else {
											navigate("/configuracoesParametros");
										}
									}}
									disabled={formSaving}
								>
									Cancelar
								</Button>
							</>
						)}
					</div>
				)}
			</Form>

			<Modal
				show={formModal}
				onHide={() => {
					setFormModal(false);
				}}
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>Adicionar Domínio</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<ListGroup>
						{queryDominios.data?.map((dominio, dominioKey) => {
							return (
								<ListGroup.Item
									key={dominioKey}
									action
									onClick={() => {
										handleAdicionar(dominio);
									}}
								>
									<span className="fw-medium">{dominio.title}: </span>
									{dominio.domainOptions
										.flatMap((item) => {
											return item.title;
										})
										.join(" | ") ?? "..."}
								</ListGroup.Item>
							);
						})}
					</ListGroup>
				</Modal.Body>
			</Modal>

			<Modal
				show={tipoModal}
				onHide={() => {
					setTipoModal(false);
				}}
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>Tipos</Modal.Title>
				</Modal.Header>
				<Modal.Body className="text-center">
					<Form.Group className="mb-3 text-start" controlId="titulo">
						<Form.Label>Adicionar Novo Tipo</Form.Label>
						<InputGroup>
							<Form.Control
								type="text"
								placeholder="Título"
								value={tipoTituloModal}
								onChange={(e) => {
									setTipoTituloModal(e.currentTarget.value);
								}}
							/>
							<Button variant="primary" className="border" onClick={handleTipoSalvar} disabled={tipoLoadingModal}>
								<Icon path={mdiCheck} size={1} />
							</Button>
						</InputGroup>
					</Form.Group>
					<div>
						{tiposModal?.map((tipo, tipoIndex) => {
							return (
								<InputGroup className="mb-2" key={tipo.id}>
									<Form.Control
										type="text"
										placeholder="Informe aqui"
										value={tipo.title}
										onChange={(e) => {
											tipo.title = e.currentTarget.value;
											let tipos = [...tiposModal];
											tipos[tipoIndex] = tipo;
											setTiposModal(tipos);
										}}
										disabled={tipo.id !== tipoIdModal}
									/>
									{tipo.id !== tipoIdModal ? (
										<>
											<Button
												variant="light"
												className="border"
												onClick={() => {
													setTipoIdModal(tipo.id);
												}}
												disabled={tipoLoadingModal}
											>
												<Icon path={mdiPencil} size={1} />
											</Button>
											<Button
												variant="light"
												className="border"
												onClick={() => {
													handleTipoRemover(Number(tipo.id));
												}}
												disabled={tipoLoadingModal || !tipo.isDeletable}
											>
												<Icon path={mdiTrashCanOutline} size={1} className="text-danger" />
											</Button>
										</>
									) : (
										<>
											<Button
												variant="primary"
												className="border"
												onClick={() => {
													handleTipoEditar(tipo);
												}}
												disabled={tipoLoadingModal}
											>
												<Icon path={mdiCheck} size={1} />
											</Button>
											<Button
												variant="light"
												className="border"
												onClick={() => {
													setTiposModal(queryTipos.data);
													setTipoIdModal(undefined);
												}}
												disabled={tipoLoadingModal}
											>
												<Icon path={mdiClose} size={1} />
											</Button>
										</>
									)}
								</InputGroup>
							);
						})}
					</div>
				</Modal.Body>
			</Modal>
		</Layout>
	);
}

type FormOpcoesProps = {
	formStatus: boolean;
	control: Control<ParameterEntity>;
	register: UseFormRegister<ParameterEntity>;
	getValues: UseFormGetValues<ParameterEntity>;
	parentFieldIndex: number;
};

function FormOpcoes(props: FormOpcoesProps) {
	const { fields } = useFieldArray({ control: props.control, name: `parameterDomains.${props.parentFieldIndex}.parameterDomainOptions` });

	return (
		<>
			{fields.map((opcao, opcaoIndex) => {
				const enabled = props.getValues(`parameterDomains.${props.parentFieldIndex}.parameterDomainOptions.${opcaoIndex}.enabled`);
				return (
					<Row key={opcao.id} className="mb-2">
						<Col>
							<InputGroup>
								<InputGroup.Text>
									<small className="text-muted">Titulo</small>
								</InputGroup.Text>
								<Form.Control
									type="text"
									placeholder="Informe aqui"
									{...props.register(`parameterDomains.${props.parentFieldIndex}.parameterDomainOptions.${opcaoIndex}.domainOption.title`)}
									disabled={true}
								/>
							</InputGroup>
						</Col>
						<Col>
							<InputGroup>
								<InputGroup.Text>
									<small className="text-muted">Valor</small>
								</InputGroup.Text>
								<Form.Control
									type="text"
									placeholder="Informe aqui"
									{...props.register(`parameterDomains.${props.parentFieldIndex}.parameterDomainOptions.${opcaoIndex}.domainOption.value`)}
									disabled={true}
								/>
							</InputGroup>
						</Col>
						<Col>
							<InputGroup>
								<InputGroup.Text>
									<small className="text-muted">Informações</small>
								</InputGroup.Text>
								<Form.Control
									type="text"
									placeholder="Informe aqui"
									{...props.register(`parameterDomains.${props.parentFieldIndex}.parameterDomainOptions.${opcaoIndex}.info`)}
									disabled={!props.formStatus}
								/>
							</InputGroup>
						</Col>
						<Col>
							<InputGroup>
								<Form.Check
									type="switch"
									className="d-flex gap-2 pt-1"
									label={enabled ? "Habilitado" : "Desabilitado"}
									{...props.register(`parameterDomains.${props.parentFieldIndex}.parameterDomainOptions.${opcaoIndex}.enabled`)}
									disabled={!props.formStatus}
								/>
							</InputGroup>
						</Col>
					</Row>
				);
			})}
		</>
	);
}
