import { UserLoginPostResponseEntity } from "../entities/UserEntity";
import { EscalaType, ModuleEntity, ModulePermissionEntity } from "./defines";

export function modulePermissionCheck(user: UserLoginPostResponseEntity, module: ModuleEntity, permission?: ModulePermissionEntity): boolean {
	return user.modules.some((userModule) => {
		if (permission) {
			return userModule.permissions.some((userPermission) => {
				return userPermission === permission;
			});
		}
		return userModule.id === module;
	});
}

export function escalaCalculo(escala: EscalaType, value: number) {
	let keys = Object.keys(escala);
	for (let i in keys) {
		if (value >= escala[keys[i]].faixa[0] && value < escala[keys[i]].faixa[1]) {
			return { index: keys[i], data: escala[keys[i]] };
		}
	}
	return { index: keys[0], data: escala[keys[0]] };
}
