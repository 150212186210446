import { useNavigate } from "react-router-dom";
import { Alert, Button, Col, InputGroup, Row, Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import Select from "react-select";
import Icon from "@mdi/react";
import { mdiBroom, mdiCardRemoveOutline, mdiChevronRight, mdiViewDashboardOutline } from "@mdi/js";

import { SkeletonCount } from "../../config/defines";
import { screens } from "../../config/screens";
import { useAuth } from "../../context/AuthContext";
import DashboardService from "../../services/DashboardService";
import ClientService from "../../services/ClientService";
import UnitService from "../../services/UnitService";
import { DashboardGetResponseEntity } from "../../entities/DashboardEntity";

import DashboardCard from "../../components/DashboardCard";
import ListCard from "../../components/ListCard";
import Layout from "../../components/Layout";
import { useEffect, useState } from "react";
import { SelectGroupStartStyle, SelectOptions } from "../../config/select";

export default function Dashboard() {
	const dashboardService = new DashboardService();
	const clientService = new ClientService();
	const unitService = new UnitService();

	const { user } = useAuth();
	const navigate = useNavigate();

	const [filtroClient, setFiltroClient] = useState<{ label: string; value: number } | null>();
	const [filtroUnit, setFiltroUnit] = useState<{ label: string; value: number } | null>();

	const queryClientes = useQuery(["clientes"], () => fetchDataClientes());
	const queryUnidades = useQuery(["unidades", filtroClient?.value], () => fetchDataUnidades(filtroClient?.value), { enabled: !!filtroClient?.value });

	const { data, isLoading, isRefetching, isError } = useQuery<DashboardGetResponseEntity>(
		["dashboard", filtroClient?.value, filtroUnit?.value],
		() => fetchData(filtroClient?.value, filtroUnit?.value),
		{
			refetchOnWindowFocus: false,
			staleTime: 1000 * 60 * 60,
		}
	);

	useEffect(() => {
		let sessionFiltro = sessionStorage.getItem("dashboardFiltro");
		if (sessionFiltro) {
			let sessionFiltroJSON = JSON.parse(sessionFiltro);
			setFiltroClient(sessionFiltroJSON.client);
			setFiltroUnit(sessionFiltroJSON.unit);
		}
	}, []);

	useEffect(() => {
		if (filtroClient || filtroUnit) {
			let sessionData = {
				client: filtroClient,
				unit: filtroUnit,
			};
			sessionStorage.setItem("dashboardFiltro", JSON.stringify(sessionData));
		} else {
			sessionStorage.removeItem("dashboardFiltro");
		}
	}, [filtroClient, filtroUnit]);

	async function fetchData(clientId: any, unitId: any) {
		let resp = await dashboardService.get(clientId, unitId);
		return resp.data;
	}

	async function fetchDataClientes() {
		let resp = await clientService.getClients();
		return resp.data;
	}

	async function fetchDataUnidades(clientId: any) {
		let resp = await unitService.getUnits(clientId);
		return resp.data;
	}

	function handleClick(item: string) {
		navigate(item);
	}

	function handleLimpar() {
		setFiltroClient(null);
		setFiltroUnit(null);
		sessionStorage.removeItem("avaliacoesFiltro");
	}

	const formClienteOptions = queryClientes.data?.map((item) => {
		return { label: `${item.name}`, value: item.id };
	});

	const formUnidadeOptions = queryUnidades.data?.map((item) => {
		return { label: `${item.name}`, value: item.id };
	});

	return (
		<Layout>
			<h5 className="mt-4 mb-4 d-flex align-items-center fw-light">
				<Icon path={mdiViewDashboardOutline} size={1} className="me-1" /> Dashboard
				{isRefetching && <Spinner size="sm" className="ms-1" variant="secondary" />}
				<div className="d-flex flex-wrap gap-2 float-right ms-auto fs-6" style={{ marginTop: -10, marginBottom: -10 }}>
					<InputGroup style={{ width: "auto" }}>
						<InputGroup.Text className="bg-white">Cliente</InputGroup.Text>
						<Select
							placeholder={"Selecione"}
							value={filtroClient}
							options={formClienteOptions}
							onChange={(value) => {
								setFiltroClient(value);
								if (!value) {
									setFiltroUnit(null);
								}
							}}
							isClearable={true}
							styles={SelectGroupStartStyle}
							{...SelectOptions}
						/>
					</InputGroup>
					<InputGroup style={{ width: "auto" }}>
						<InputGroup.Text className="bg-white">Unidade</InputGroup.Text>
						<Select
							placeholder={"Selecione"}
							value={filtroUnit}
							options={formUnidadeOptions}
							onChange={(value) => {
								setFiltroUnit(value);
							}}
							isClearable={true}
							styles={SelectGroupStartStyle}
							{...SelectOptions}
						/>
					</InputGroup>
					<Button className="shadow-sm rounded-3" variant="light" onClick={handleLimpar}>
						<Icon path={mdiBroom} size={1} />
					</Button>
				</div>
			</h5>

			<Row className="mb-4">
				{isLoading &&
					Array.from({ length: Number(SkeletonCount) }, (_, index) => {
						return (
							<Col md={12} key={index}>
								<DashboardCard skeleton />
							</Col>
						);
					})}
				{!isLoading && isError && (
					<Col md={12}>
						<Alert variant="secondary" className="text-center">
							Problema na operação. Tente novamente mais tarde
						</Alert>
					</Col>
				)}
				{!isLoading && data && data.cards.length === 0 && (
					<Col md={12}>
						<Alert variant="light" className="text-center p-4 d-flex justify-content-center align-items-center">
							<Icon path={mdiCardRemoveOutline} size={1} className="me-2" />
							Nenhum registro encontrado
						</Alert>
					</Col>
				)}
				{!isLoading &&
					data &&
					data.cards.length >= 1 &&
					data.cards.map((item) => {
						return <DashboardCard data={item} clientId={filtroClient?.value} unitId={filtroUnit?.value} key={item.id} />;
					})}
			</Row>

			<Row className="mb-4">
				{screens
					.filter((screen) => screen.showDashboard)
					.filter(
						(screen) =>
							screen.module === null ||
							user?.modules?.some((module) => {
								return module.id === screen.module;
							})
					)
					.filter((screen) => {
						return screen.superUser ? user?.superUser : true;
					})
					.map((screen, screenKey) => {
						return (
							<Col lg={6} key={screenKey} className="mb-2">
								<ListCard
									onClick={() => {
										handleClick(screen.route);
									}}
								>
									<ListCard.Icon>
										<Icon path={screen.icon} size={1} className="text-muted me-3" />
									</ListCard.Icon>
									<ListCard.Content>
										<div className="flex-fill me-2">
											<div className="text-muted fw-lighter">{screen.name}</div>
											<div className="fw-light">{screen.description}</div>
										</div>
									</ListCard.Content>
									<ListCard.Action>
										<Icon path={mdiChevronRight} size={1} className="text-muted m-2" />
									</ListCard.Action>
								</ListCard>
							</Col>
						);
					})}
			</Row>
		</Layout>
	);
}
