import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import Icon from "@mdi/react";
import { mdiAccountGroupOutline, mdiAccountMultipleOutline, mdiChevronRight, mdiLockOutline, mdiOfficeBuildingOutline } from "@mdi/js";

import Layout from "../../components/Layout";
import ListCard from "../../components/ListCard";
import { useAuth } from "../../context/AuthContext";
import { ModuleEntity } from "../../config/defines";

export default function Acessos() {
	const { user } = useAuth();
	const navigate = useNavigate();

	function handleClick(item: string) {
		navigate(item);
	}

	return (
		<Layout>
			<h5 className="mt-4 mb-4 d-flex align-items-center fw-light">
				<Icon path={mdiLockOutline} size={1} className="me-1" /> Acessos
			</h5>

			<Row className="mb-4">
				{user?.modules?.some((module) => {
					return module.id === ModuleEntity.acessos;
				}) && (
					<Col md={12}>
						<ListCard
							onClick={() => {
								handleClick("/grupos");
							}}
						>
							<ListCard.Icon>
								<Icon path={mdiAccountGroupOutline} size={1} className="text-muted me-3" />
							</ListCard.Icon>
							<ListCard.Content>
								<div className="flex-fill me-2">
									<div className="text-muted fw-lighter">Grupos</div>
									<div className="fw-light">Lista de Grupos de usuários para gerenciar permissões</div>
								</div>
							</ListCard.Content>
							<ListCard.Action>
								<Icon path={mdiChevronRight} size={1} className="text-muted m-2" />
							</ListCard.Action>
						</ListCard>
					</Col>
				)}
				{user?.modules?.some((module) => {
					return module.id === ModuleEntity.acessos;
				}) && (
					<Col md={12}>
						<ListCard
							onClick={() => {
								handleClick("/usuarios");
							}}
						>
							<ListCard.Icon>
								<Icon path={mdiAccountMultipleOutline} size={1} className="text-muted me-3" />
							</ListCard.Icon>
							<ListCard.Content>
								<div className="flex-fill me-2">
									<div className="text-muted fw-lighter">Usuários</div>
									<div className="fw-light">Lista de usuários para gerenciar acessos</div>
								</div>
							</ListCard.Content>
							<ListCard.Action>
								<Icon path={mdiChevronRight} size={1} className="text-muted m-2" />
							</ListCard.Action>
						</ListCard>
					</Col>
				)}
				{user?.superUser &&
					user?.modules?.some((module) => {
						return module.id === ModuleEntity.acessos;
					}) && (
						<Col md={12}>
							<ListCard
								onClick={() => {
									handleClick("/empresas");
								}}
							>
								<ListCard.Icon>
									<Icon path={mdiOfficeBuildingOutline} size={1} className="text-muted me-3" />
								</ListCard.Icon>
								<ListCard.Content>
									<div className="flex-fill me-2">
										<div className="text-muted fw-lighter">Empresas</div>
										<div className="fw-light">Lista de empresas para gerenciar</div>
									</div>
								</ListCard.Content>
								<ListCard.Action>
									<Icon path={mdiChevronRight} size={1} className="text-muted m-2" />
								</ListCard.Action>
							</ListCard>
						</Col>
					)}
			</Row>
		</Layout>
	);
}
