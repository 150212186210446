import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import Icon from "@mdi/react";
import { mdiBookCogOutline, mdiChevronRight, mdiCogOutline, mdiDatabaseOutline, mdiTune } from "@mdi/js";

import ListCard from "../../components/ListCard";
import Layout from "../../components/Layout";

export default function Configuracoes() {
	const navigate = useNavigate();

	function handleClick(item: string) {
		navigate(item);
	}

	return (
		<Layout>
			<h5 className="mt-4 mb-4 d-flex align-items-center fw-light">
				<Icon path={mdiCogOutline} size={1} className="me-1" /> Configurações
			</h5>

			<Row className="mb-4">
				<Col md={12}>
					<ListCard
						onClick={() => {
							handleClick("/configuracoesAvaliacoes");
						}}
					>
						<ListCard.Icon>
							<Icon path={mdiBookCogOutline} size={1} className="text-muted me-3" />
						</ListCard.Icon>
						<ListCard.Content>
							<div className="flex-fill me-2">
								<div className="text-muted fw-lighter">Avaliações</div>
								<div className="fw-light">Lista de avaliações para gerenciar</div>
							</div>
						</ListCard.Content>
						<ListCard.Action>
							<Icon path={mdiChevronRight} size={1} className="text-muted m-2" />
						</ListCard.Action>
					</ListCard>
				</Col>
				<Col md={12}>
					<ListCard
						onClick={() => {
							handleClick("/configuracoesParametros");
						}}
					>
						<ListCard.Icon>
							<Icon path={mdiTune} size={1} className="text-muted me-3" />
						</ListCard.Icon>
						<ListCard.Content>
							<div className="flex-fill me-2">
								<div className="text-muted fw-lighter">Parâmetros</div>
								<div className="fw-light">Lista de parâmetros para gerenciar</div>
							</div>
						</ListCard.Content>
						<ListCard.Action>
							<Icon path={mdiChevronRight} size={1} className="text-muted m-2" />
						</ListCard.Action>
					</ListCard>
				</Col>
				<Col md={12}>
					<ListCard
						onClick={() => {
							handleClick("/configuracoesDominios");
						}}
					>
						<ListCard.Icon>
							<Icon path={mdiDatabaseOutline} size={1} className="text-muted me-3" />
						</ListCard.Icon>
						<ListCard.Content>
							<div className="flex-fill me-2">
								<div className="text-muted fw-lighter">Domínios</div>
								<div className="fw-light">Lista de domínios para gerenciar</div>
							</div>
						</ListCard.Content>
						<ListCard.Action>
							<Icon path={mdiChevronRight} size={1} className="text-muted m-2" />
						</ListCard.Action>
					</ListCard>
				</Col>
			</Row>
		</Layout>
	);
}
