import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { Button, Card, Form, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import CheckboxTree from "react-checkbox-tree";
import Select from "react-select";
import { AxiosError, AxiosResponse } from "axios";
import Icon from "@mdi/react";
import {
	mdiAccountGroupOutline,
	mdiCheckboxBlankOutline,
	mdiCheckboxIntermediate,
	mdiCheckboxMarkedOutline,
	mdiChevronDown,
	mdiChevronLeft,
	mdiChevronRight,
	mdiUnfoldLessHorizontal,
	mdiUnfoldMoreHorizontal,
} from "@mdi/js";

import { ModuleEntity, ModulePermissionEntity } from "../../config/defines";
import { SelectOptions, SelectStyle } from "../../config/select";
import { modulePermissionCheck } from "../../config/utils";
import { useToast } from "../../context/ToastContext";
import { useAuth } from "../../context/AuthContext";
import {
	AccessControlListGetGrupoResponseEntity,
	AccessControlListGetPermissoesResponseEntity,
	AccessControlListPostGrupoRequestEntity,
	AccessControlListPostGrupoResponseEntity,
	AccessControlListPutGrupoRequestEntity,
	AccessControlListPutGrupoResponseEntity,
} from "../../entities/AccessControlListEntity";
import AccessControlListService from "../../services/AccessControlListService";
import CompanyService from "../../services/CompanyService";

import Layout from "../../components/Layout";

const toastTitle = "Grupo";

export default function GrupoFormulario() {
	const accessControlListService = new AccessControlListService();
	const companyService = new CompanyService();

	const navigate = useNavigate();
	const { user } = useAuth();
	const { id } = useParams();
	const { handleToast } = useToast();

	const [formStatus, setFormStatus] = useState(id ? false : true);
	const [formSaving, setFormSaving] = useState(false);

	const [formPermissoesOptions, setFormPermissoesOptions] = useState<any>();
	const [formPermissoesChecked, setFormPermissoesChecked] = useState<string[]>();
	const [formPermissoesExpanded, setFormPermissoesExpanded] = useState<string[]>();
	const [formPermissoesParents, setFormPermissoesParents] = useState<{ value: number; parent: number | null }[]>();

	useQuery("permissoes", () => fetchDataPermissoes());
	const queryEmpresas = useQuery("empresas", () => fetchDataEmpresas());

	const { data, isLoading, isFetching, isRefetching } = useQuery<AccessControlListGetGrupoResponseEntity>(["grupo", id], () => fetchData(id), { enabled: !!id && !formStatus });

	const mutationPost = useMutation(mutatePost, { onSuccess: mutatePostSuccess, onError: mutateError });
	const mutationPut = useMutation(mutatePut, { onSuccess: mutatePutSuccess, onError: mutateError });

	const { register, handleSubmit, setValue, reset } = useForm({ defaultValues: data });

	async function fetchData(id: any) {
		let resp = await accessControlListService.getGroup(id);
		setFormPermissoesChecked(
			resp.data.relacNodos.map((item) => {
				return `${item.relacNodoId}`;
			}) ?? undefined
		);
		reset(resp.data);
		return resp.data;
	}

	async function fetchDataEmpresas() {
		let resp = await companyService.getCompanies();
		return resp.data;
	}

	async function fetchDataPermissoes() {
		let resp = await accessControlListService.getPermissionTree();
		setFormPermissoesOptions([handlePermissoes(resp.data.root)]);
		setFormPermissoesParents(handlePermissoesParents(resp.data.root, null));
		return resp.data;
	}

	async function mutatePost(data: AccessControlListPostGrupoRequestEntity) {
		setFormSaving(true);
		return await accessControlListService.postGroup(1, data);
	}

	function mutatePostSuccess(resp: AxiosResponse<AccessControlListPostGrupoResponseEntity, any>) {
		handleToast(toastTitle, "Informações salvas com sucesso!", 5000);
		navigate("/grupoFormulario/" + resp.data.id);
		setFormStatus(false);
		setFormSaving(false);
	}

	async function mutatePut(data: AccessControlListPutGrupoRequestEntity) {
		setFormSaving(true);
		return await accessControlListService.putGroup(data);
	}

	function mutatePutSuccess(resp: AxiosResponse<AccessControlListPutGrupoResponseEntity, any>) {
		handleToast(toastTitle, "Informações salvas com sucesso!", 5000);
		setFormStatus(false);
		setFormSaving(false);
	}

	function mutateError(resp: AxiosError) {
		handleToast(toastTitle, `Problema na operação: ${resp.message}`, 5000, "danger");
		setFormSaving(false);
	}

	function handlePermissoes(permissao: AccessControlListGetPermissoesResponseEntity["root"]) {
		let permissaoOption: any = {
			value: permissao.relacNodoId ?? 0,
			label: permissao.recursoNome,
		};

		if (permissao.filhos.length >= 1) {
			permissaoOption["children"] = permissao.filhos.map((filho) => {
				return handlePermissoes(filho);
			});
		}

		return permissaoOption;
	}

	function handlePermissoesParents(permissao: AccessControlListGetPermissoesResponseEntity["root"], parent: number | null) {
		let permissaoParent = [
			{
				value: permissao.relacNodoId ?? 0,
				parent: parent,
			},
		];

		if (permissao.filhos.length >= 1) {
			permissao.filhos.forEach((filho: any) => {
				permissaoParent = [...permissaoParent, ...handlePermissoesParents(filho, permissao.relacNodoId)];
			});
		}

		return permissaoParent;
	}

	function handlePermissoesParentsFind(permissao: number) {
		let permissoesParents: number[] = [];
		let permissaoParent = formPermissoesParents?.find((itemParent) => {
			return permissao === itemParent.value;
		});
		if (permissaoParent && permissaoParent.parent) {
			permissoesParents.push(permissaoParent.parent);
			permissoesParents = [...permissoesParents, ...handlePermissoesParentsFind(permissaoParent.parent)];
		}

		return permissoesParents;
	}

	async function handleEmpresa(value: any) {
		setValue(`empresaId`, value ? value.value : undefined);
	}

	const formEmpresaOptions = queryEmpresas.data?.map((item) => {
		return { label: `${item.name}`, value: item.id };
	});

	return (
		<Layout>
			<Form
				onSubmit={handleSubmit((values) => {
					let relacNodos: number[] = [];
					if (formPermissoesChecked) {
						relacNodos = formPermissoesChecked
							.map((item) => {
								return Number(item);
							})
							.filter((item) => {
								return item !== 0;
							});
						let permissoesParents: number[] = [];
						relacNodos.forEach((item) => {
							permissoesParents = [...permissoesParents, ...handlePermissoesParentsFind(item)];
						});
						relacNodos = [...relacNodos, ...permissoesParents].filter((v, i, a) => a.indexOf(v) === i).sort((a, b) => a - b);
					}

					if (id) {
						mutationPut.mutate({
							grupoId: values.grupoId,
							nome: values.grupoNome,
							empresaId: values.empresaId,
							relacNodos: relacNodos,
						});
					} else {
						mutationPost.mutate({
							nome: values.grupoNome,
							empresaId: values.empresaId,
							relacNodos: relacNodos,
						});
					}
				})}
			>
				<h5 className="mt-4 mb-4 d-flex align-items-center fw-light">
					<Link to={"/grupos"} className="d-flex align-items-center text-decoration-none">
						<Icon path={mdiChevronLeft} size={1} className="me-1" /> <Icon path={mdiAccountGroupOutline} size={1} className="me-1" /> Formulário de Grupo
					</Link>
					{(isLoading || isFetching || isRefetching) && <Spinner size="sm" className="ms-1" variant="secondary" />}
					<div className="d-flex gap-2 float-right ms-auto" style={{ marginTop: -10, marginBottom: -10 }}>
						{modulePermissionCheck(user!, ModuleEntity.acessos, ModulePermissionEntity.editar) && (
							<>
								{!formStatus && (
									<Button
										variant="light"
										className="rounded-3 shadow-sm"
										onClick={() => {
											setFormStatus(true);
										}}
									>
										Editar Informações
									</Button>
								)}
								{formStatus && (
									<>
										<Button
											variant="light"
											className="rounded-3 shadow-sm"
											type="button"
											onClick={() => {
												if (id) {
													setFormStatus(false);
												} else {
													navigate("/grupos");
												}
											}}
											disabled={formSaving}
										>
											Cancelar
										</Button>
										<Button variant="primary" className="rounded-3 shadow-sm" type="submit" disabled={formSaving}>
											{formSaving ? (
												<>
													<Spinner animation="border" size="sm" className="me-2" /> Salvando
												</>
											) : (
												"Salvar Informações"
											)}
										</Button>
									</>
								)}
							</>
						)}
					</div>
				</h5>

				<Card className="mb-3">
					<Card.Body>
						<Form.Group className="mb-3" controlId="nome">
							<Form.Label>Nome</Form.Label>
							<Form.Control type="text" placeholder="Informe aqui" {...register("grupoNome", { required: true })} disabled={!formStatus} />
						</Form.Group>
						{user?.superUser && (
							<Form.Group className="mb-3" controlId="empresa">
								<Form.Label>Empresa</Form.Label>
								{!queryEmpresas.isFetched && isLoading && (
									<div className="form-control disabled">
										<Spinner size="sm" />
									</div>
								)}
								{queryEmpresas.isFetched && !isLoading && (
									<Select
										placeholder={"Selecione"}
										defaultValue={formEmpresaOptions?.find((option) => {
											return option.value === data?.empresaId;
										})}
										options={formEmpresaOptions}
										onChange={(value) => {
											handleEmpresa(value);
										}}
										styles={SelectStyle}
										isDisabled={!formStatus}
										{...SelectOptions}
									/>
								)}
							</Form.Group>
						)}
						<Form.Group className="mb-3" controlId="permissoes">
							<Form.Label>Permissões</Form.Label>
							<div className={`form-control ${formStatus ? "" : "disabled"}`}>
								{!formPermissoesOptions && <Spinner size="sm" />}
								{formPermissoesOptions && (
									<CheckboxTree
										nodes={formPermissoesOptions}
										checked={formPermissoesChecked}
										expanded={formPermissoesExpanded}
										checkModel={"all"}
										onCheck={(checked) => setFormPermissoesChecked(checked)}
										onExpand={(expanded) => setFormPermissoesExpanded(expanded)}
										showExpandAll
										showNodeIcon={false}
										icons={{
											check: <Icon path={mdiCheckboxMarkedOutline} size={0.8} />,
											uncheck: <Icon path={mdiCheckboxBlankOutline} size={0.8} />,
											halfCheck: <Icon path={mdiCheckboxIntermediate} size={0.8} />,
											expandClose: <Icon path={mdiChevronRight} size={1} className="text-secondary" />,
											expandOpen: <Icon path={mdiChevronDown} size={1} />,
											expandAll: <Icon path={mdiUnfoldMoreHorizontal} size={1} />,
											collapseAll: <Icon path={mdiUnfoldLessHorizontal} size={1} />,
										}}
										disabled={!formStatus}
									/>
								)}
							</div>
						</Form.Group>
					</Card.Body>
				</Card>

				{modulePermissionCheck(user!, ModuleEntity.acessos, ModulePermissionEntity.editar) && (
					<div className="d-flex gap-2 mb-4">
						{!formStatus && (
							<Button
								variant="light"
								className="rounded-3 shadow-sm"
								onClick={() => {
									setFormStatus(true);
								}}
							>
								Editar Informações
							</Button>
						)}
						{formStatus && (
							<>
								<Button variant="primary" className="rounded-3 shadow-sm" type="submit" disabled={formSaving}>
									{formSaving ? (
										<>
											<Spinner animation="border" size="sm" className="me-2" /> Salvando
										</>
									) : (
										"Salvar Informações"
									)}
								</Button>
								<Button
									variant="light"
									className="rounded-3 shadow-sm"
									type="button"
									onClick={() => {
										if (id) {
											setFormStatus(false);
										} else {
											navigate("/grupos");
										}
									}}
									disabled={formSaving}
								>
									Cancelar
								</Button>
							</>
						)}
					</div>
				)}
			</Form>
		</Layout>
	);
}
