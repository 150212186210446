import { createContext, ReactNode, useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { TokenRefreshInterval } from "../config/defines";
import { screens } from "../config/screens";
import { UserLoginPostResponseEntity } from "../entities/UserEntity";
import UserService from "../services/UserService";
import axios, { AxiosError } from "axios";
import moment from "moment";

type AuthContextProps = {
	user: UserLoginPostResponseEntity | undefined;
	aplicativo: any;
	routeActive: string;
	geolocation: GeolocationPosition | undefined;
	handleLogin: (email: string, password: string) => Promise<true | string>;
	handleLogout: () => void;
	handleAplicativo: (id: number) => void;
};

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

export function useAuth() {
	return useContext(AuthContext);
}

type AuthProviderProps = {
	children: ReactNode;
};

const locations = screens
	.filter((item) => {
		return item.showSidebar;
	})
	.map((item) => {
		return item.route;
	});

export default function AuthProvider({ children }: AuthProviderProps) {
	const userService = new UserService();

	const location = useLocation();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(true);
	const [user, setUser] = useState<UserLoginPostResponseEntity | undefined>();
	const [aplicativo, setAplicativo] = useState<number>(1);
	const [routeActive, setRouteActive] = useState<string>("");
	const [geolocation, setGeolocation] = useState<GeolocationPosition>();
	const handleTokenRefreshIntervalRef = useRef(false);

	useEffect(() => {
		if (locations.includes(location.pathname)) {
			setRouteActive(location.pathname);
		}
	}, [location]);

	useEffect(() => {
		handleInitial();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (user && handleTokenRefreshIntervalRef.current === false) {
			handleTokenRefreshIntervalRef.current = true;
			handleTokenRefreshInterval();
		}
		// eslint-disable-next-line
	}, [user]);

	useEffect(() => {
		let watchPosition: any;
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition((position) => {
				setGeolocation(position);
			});
			watchPosition = navigator.geolocation.watchPosition((position) => {
				setGeolocation(position);
			});
		}
		return () => {
			navigator.geolocation.clearWatch(watchPosition);
		};
	}, []);

	function handleInitial() {
		let sessionUser = sessionStorage.getItem("user");
		if (sessionUser) {
			let userJson = JSON.parse(sessionUser);
			setUser(userJson);
		}

		let sessionAplicativo = sessionStorage.getItem("aplicativo");
		if (sessionAplicativo) {
			let aplicativoInt = parseInt(sessionAplicativo);
			setAplicativo(aplicativoInt);
		}
		setLoading(false);
	}

	async function handleLogin(email: string, password: string) {
		let data = {
			login: email,
			senha: password,
			appVersion: "0.0.1",
			deviceToken: "",
			deviceType: 0,
			osVersion: "",
		};

		try {
			let response = await userService.login(data);
			if (response.status === 200 && response.data) {
				sessionStorage.setItem("user", JSON.stringify(response.data));
				sessionStorage.setItem("tokenRefresh", moment().toISOString());
				setUser(response.data);
				return true;
			}
		} catch (error: any) {
			if (axios.isAxiosError(error)) {
				const axiosError = error as AxiosError<any>;
				if (axiosError.response) {
					return axiosError.response.data.message;
				}
				return axiosError.message;
			}
			return error.message;
		}
	}

	function handleLogout() {
		setUser(undefined);
		sessionStorage.removeItem("user");
		sessionStorage.removeItem("tokenRefresh");
		navigate("/");
	}

	async function handleAplicativo(id: number) {
		sessionStorage.setItem("aplicativo", id.toString());
		setAplicativo(id);
	}

	function handleTokenRefreshInterval() {
		let sessionTokenRefresh = sessionStorage.getItem("tokenRefresh");
		if (sessionTokenRefresh) {
			let minutesDiff = moment().diff(moment(sessionTokenRefresh), "minutes");
			if (minutesDiff > TokenRefreshInterval) {
				handleTokenRefresh();
			} else {
				setTimeout(() => {
					handleTokenRefresh();
				}, (TokenRefreshInterval - minutesDiff) * 1000 * 60);
			}
		}
	}

	async function handleTokenRefresh() {
		try {
			const userService = new UserService();
			let resp = await userService.getTokenRefresh();
			if (user && resp.data) {
				let userData = { ...user, hashLogin: resp.data };
				sessionStorage.setItem("user", JSON.stringify(userData));
				sessionStorage.setItem("tokenRefresh", moment().toISOString());
				setUser(userData);
			}
		} catch (error: any) {
			if (axios.isAxiosError(error)) {
				const axiosError = error as AxiosError<any>;
				if (axiosError.response?.status === 401) {
					handleLogout();
				}
			}
		}

		setTimeout(() => {
			handleTokenRefresh();
		}, TokenRefreshInterval * 1000 * 60);
	}

	const value = {
		user,
		aplicativo,
		routeActive,
		geolocation,
		handleLogin,
		handleLogout,
		handleAplicativo,
	};

	if (loading) {
		return <></>;
	}

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
