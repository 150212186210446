import ApiService from "./ApiService";
import {
	QuestionnaireDeleteResponse,
	QuestionnaireGetQuestionnairesResponse,
	QuestionnaireGetResponse,
	QuestionnairePostRequest,
	QuestionnairePostResponse,
	QuestionnairePutRequest,
	QuestionnairePutResponse,
} from "../entities/QuestionnaireEntity";

export default class QuestionnaireService extends ApiService {
	public async getQuestionnaires() {
		return await this.request.get<QuestionnaireGetQuestionnairesResponse[]>(`/api/v1/Questionnaires`);
	}
	public async getQuestionnairesResponses(headers: any = {}) {
		return await this.request.get<QuestionnaireGetResponse[]>(`/api/v1/Questionnaires`, { headers: headers });
	}
	public async get(id: number) {
		return await this.request.get<QuestionnaireGetResponse>(`/api/v1/Questionnaire/${id}`);
	}
	public async post(data: QuestionnairePostRequest) {
		return await this.request.post<QuestionnairePostResponse>(`/api/v1/Questionnaire`, data);
	}
	public async put(id: number, data: QuestionnairePutRequest) {
		return await this.request.put<QuestionnairePutResponse>(`/api/v1/Questionnaire/${id}`, data);
	}
	public async delete(id: number) {
		return await this.request.delete<QuestionnaireDeleteResponse>(`/api/v1/Questionnaire/${id}`);
	}
	public async duplicate(id: number) {
		return await this.request.post<QuestionnaireDeleteResponse>(`/api/v1/Questionnaire/${id}/Duplicate`);
	}
}
