import { useQuery } from "react-query";
import { Card, Col } from "react-bootstrap";

import { DashboardCardExtra, DashboardCardsEntity, DashboardCardSizeApex, DashboardCardType, DashboardGetCardResponseEntity } from "../../entities/DashboardEntity";
import DashboardService from "../../services/DashboardService";

import DashboardChartRadial from "../DashboardChartRadial";
import DashboardNumberCard from "../DashboardNumberCard";
import DashboardChartRadar from "../DashboardChartRadar";
import DashboardChartBar from "../DashboardChartBar";

type DashboardCardPropsContent = {
	data: DashboardCardsEntity;
	clientId?: any;
	unitId?: any;
	skeleton?: never;
};

type DashboardCardPropsSkeleton = {
	data?: never;
	clientId?: never;
	unitId?: never;
	skeleton: true;
};

type DashboardCardProps = DashboardCardPropsContent | DashboardCardPropsSkeleton;

const dashboardService = new DashboardService();

export default function DashboardCard(props: DashboardCardProps) {
	const { data, isLoading } = useQuery<DashboardGetCardResponseEntity>(
		["dashboard", "card", props.data?.id, props.clientId, props.unitId],
		() => fetchData(props.data?.id, props.clientId, props.unitId),
		{
			enabled: !!props.data?.id,
			refetchOnWindowFocus: false,
			staleTime: 1000 * 60 * 60,
		}
	);

	async function fetchData(id: any, clientId: any, unitId: any) {
		let resp = await dashboardService.getCard(id, clientId, unitId);
		return resp.data;
	}

	return (
		<Col lg={DashboardCardSizeApex[props.data?.size ?? 3]}>
			{props.skeleton && (
				<Card className="border-0 shadow mb-4">
					<Card.Body className="p-4">
						<div className="skeleton skeleton-button" style={{ height: 300 }}>
							...
						</div>
					</Card.Body>
				</Card>
			)}

			{!props.skeleton && (
				<>
					{props.data?.type === DashboardCardType.Value && <DashboardNumberCard data={data} loading={isLoading} options={DashboardCardExtra[props.data.id]} />}
					{props.data?.type === DashboardCardType.Radar && <DashboardChartRadar data={data} loading={isLoading} options={DashboardCardExtra[props.data.id]} />}
					{props.data?.type === DashboardCardType.Bar && <DashboardChartBar data={data} loading={isLoading} options={DashboardCardExtra[props.data.id]} />}
					{props.data?.type === DashboardCardType.RadialBar && <DashboardChartRadial data={data} loading={isLoading} options={DashboardCardExtra[props.data.id]} />}
				</>
			)}
		</Col>
	);
}
