import { PerguntaTipo } from "../entities/QuestionnaireEntity";

export type Unpacked<T> = T extends (infer U)[] ? U : T;

export const FormTipos = [
	{ label: "Texto", value: PerguntaTipo.text },
	{ label: "Texto múltiplas linhas", value: PerguntaTipo.textarea },
	{ label: "Número", value: PerguntaTipo.number },
	{ label: "Seleção única", value: PerguntaTipo.radio },
	{ label: "Seleção múltipla", value: PerguntaTipo.checkbox },
	{ label: "Seleção", value: PerguntaTipo.select },
	{ label: "Arquivo", value: PerguntaTipo.file },
	{ label: "Imagem", value: PerguntaTipo.image },
	{ label: "Data", value: PerguntaTipo.date },
	{ label: "Objetiva", value: PerguntaTipo.objective },
	{ label: "Geolocalização", value: PerguntaTipo.geo },
	{ label: "Parâmetro", value: PerguntaTipo.parametro },
];

export const SkeletonCount = 10;
export const PageSize = 10;
export const Location = { lat: -30.033056, lng: -51.23 };
export const DateFormat = "DD/MMM/YYYY";
export const DateTimeFormat = "DD/MMM/YYYY HH:mm:ss";
export const TokenRefreshInterval = 25; // minutos

export enum ModuleEntity {
	acessos = 1001,
	configuracoes = 1002,
	configuracoesAvaliacoes = 1003,
	configuracoesParametros = 1004,
	configuracoesDominios = 1005,
	avaliacoes = 1006,
}

export const NavigationEntity: { [key: number]: string } = {
	1001: "/usuarios",
	1002: "/avaliacoes",
	1003: "/configuracoesAvaliacoes",
};

export enum ModulePermissionEntity {
	visualizar = 9991,
	editar = 9992,
	teste = 9999,
}

export type EscalaType = {
	[key: string]: {
		titulo: string;
		descricao: string;
		faixa: number[];
	};
};

export const EscalaRisco: EscalaType = {
	"1": {
		titulo: "Sem Risco",
		descricao: "A edificação não apresenta risco estrutural.",
		faixa: [0, 20],
	},
	"2": {
		titulo: "Danos Leves",
		descricao: "A edificação apresenta incidência de danos na estrutura sem comprometer sua funcionalidade.",
		faixa: [20, 40],
	},
	"3": {
		titulo: "Danos Moderados",
		descricao: "A edificação apresenta danos na estrutura devido a ocorrência das inundações, reduzindo parcialmente a funcionalidade. Esses danos podem ser reparados.",
		faixa: [40, 60],
	},
	"4": {
		titulo: "Danos Significativos",
		descricao: "A edificação apresenta danos significativos na estrutura, comprometendo sua funcionalidade, mas podendo ser passível de reabilitação.",
		faixa: [60, 80],
	},
	"5": {
		titulo: "Comprometimento Grave",
		descricao: "Danos graves ou rupturas localizadas na estrutura, indicam comprometimento da segurança. Reforço estrutural necessário.",
		faixa: [80, 101],
	},
};

export const EscalaVunerabilidade: EscalaType = {
	"1": {
		titulo: "Funcionalidade Preservada",
		descricao:
			"Os sistemas de revestimentos, esquadrias, e instalações da edificação estão praticamente intactos. Os móveis e eletrodomésticos não apresentam danos significativos e estão em perfeitas condições de uso.",
		faixa: [0, 20],
	},
	"2": {
		titulo: "Leves perdas na Funcionalidade",
		descricao:
			"Existem pequenos danos nos sistemas de revestimentos e esquadrias, que podem incluir rachaduras, manchas ou desgastes superficiais. As instalações podem apresentar alguns problemas leves, como vazamentos ou falhas pontuais. Os móveis e eletrodomésticos podem ter danos mínimos, como arranhões ou pequenas avarias, mas ainda estão funcionalmente utilizáveis.",
		faixa: [20, 40],
	},
	"3": {
		titulo: "Funcionalidade Moderadamente Afetada",
		descricao:
			"Os sistemas de revestimentos e esquadrias apresentam danos visíveis, como rachaduras, descolamentos ou deformações. As instalações podem ter danos mais significativos, como vazamentos graves ou falhas em componentes essenciais. Os móveis e eletrodomésticos podem estar danificados de forma moderada, com riscos, amassados ou quebras parciais, comprometendo sua funcionalidade em certa medida.",
		faixa: [40, 60],
	},
	"4": {
		titulo: "Funcionalidade Significativamente Comprometida",
		descricao:
			"Os sistemas de revestimentos e esquadrias sofrem danos severos, com grandes rachaduras, descolamentos ou até mesmo colapsos parciais. As instalações apresentam falhas graves, como rupturas em tubulações ou danos estruturais em componentes importantes. Os móveis e eletrodomésticos estão severamente danificados, com quebras importantes, impossibilitando ou dificultando sua utilização.",
		faixa: [60, 80],
	},
	"5": {
		titulo: "Funcionalidade Severamente Comprometida",
		descricao:
			"Os sistemas de revestimentos e esquadrias estão gravemente comprometidos, com colapsos significativos e danos estruturais sérios. As instalações sofrem danos extensos, com falhas generalizadas e comprometimento da segurança. Os móveis e eletrodomésticos estão em estado crítico, com danos graves que inviabilizam completamente sua utilização ou representam riscos à segurança.",
		faixa: [80, 101],
	},
};

export const EscalaComprometimento: EscalaType = {
	"1": {
		titulo: "Comprometimento Muito Baixo",
		descricao:
			"A edificação possui baixo risco estrutural, baixa vulnerabilidade à inundação, e sua funcionalidade é preservada após a inundação. Os custos de intervenção são baixos e os benefícios são significativos.",
		faixa: [0, 20],
	},
	"2": {
		titulo: "Comprometimento Baixo",
		descricao:
			"A edificação apresenta um nível moderado de risco estrutural, vulnerabilidade moderada à inundação e algumas interrupções na funcionalidade após a inundação. Os custos de intervenção são moderados e os benefícios são razoáveis.",
		faixa: [20, 40],
	},
	"3": {
		titulo: "Comprometimento Moderado",
		descricao:
			"A edificação tem um nível aceitável de risco estrutural, é vulnerável à inundação em certa medida, e sua funcionalidade é afetada após a inundação. Os custos de intervenção são consideráveis, mas os benefícios podem justificar o investimento.",
		faixa: [40, 60],
	},
	"4": {
		titulo: "Comprometimento Alto",
		descricao:
			"A edificação apresenta um alto risco estrutural, é altamente vulnerável à inundação e tem uma funcionalidade significativamente comprometida após a inundação. Os custos de intervenção são altos e os benefícios podem ser limitados.",
		faixa: [60, 80],
	},
	"5": {
		titulo: "Comprometimento Muito Alto",
		descricao:
			"A edificação tem um risco estrutural muito alto, é extremamente vulnerável à inundação e sua funcionalidade é severamente comprometida após a inundação. Os custos de intervenção são proibitivos em comparação com os benefícios potenciais.",
		faixa: [80, 101],
	},
};
