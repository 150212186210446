import { useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { Button, Modal, Spinner } from "react-bootstrap";
import Icon from "@mdi/react";
import { mdiChevronRight, mdiContentDuplicate, mdiTrashCanOutline } from "@mdi/js";

import { useToast } from "../../context/ToastContext";
import { QuestionnaireGetQuestionnairesResponse } from "../../entities/QuestionnaireEntity";
import QuestionnaireService from "../../services/QuestionnaireService";

import ListCard from "../ListCard";
import axios, { AxiosError } from "axios";

type ConfiguracoesAvaliacaoCardPropsContent = {
	data: QuestionnaireGetQuestionnairesResponse;
	skeleton?: never;
};

type ConfiguracoesAvaliacaoCardPropsSkeleton = {
	data?: never;
	onClick?: never;
	onRemove?: never;
	skeleton: true;
};

type ConfiguracoesAvaliacaoCardProps = ConfiguracoesAvaliacaoCardPropsContent | ConfiguracoesAvaliacaoCardPropsSkeleton;

const toastTitle = "Avaliação";

export default function ConfiguracoesAvaliacaoCard(props: ConfiguracoesAvaliacaoCardProps) {
	const questionnaireService = new QuestionnaireService();

	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const { handleToast } = useToast();

	const [duplicateModal, setDuplicateModal] = useState(false);
	const [duplicateLoadingModal, setDuplicateLoadingModal] = useState(false);

	const [removeModal, setRemoveModal] = useState(false);
	const [removeLoadingModal, setRemoveLoadingModal] = useState(false);
	const [removed, setRemoved] = useState(false);

	function handleClick() {
		if (!props.skeleton && props.data) {
			navigate("/avaliacaoConfiguracoes/" + props.data.id);
		}
	}

	function handleClickRemove(e: any) {
		e.stopPropagation();
		setRemoveModal(true);
	}

	function handleClickRemoveClose() {
		if (!removeLoadingModal) {
			setRemoveModal(false);
		}
	}

	async function handleRemove() {
		if (!props.skeleton && props.data) {
			setRemoveLoadingModal(true);
			try {
				let resp = await questionnaireService.delete(props.data.id!);
				if (resp.status === 200) {
					queryClient.refetchQueries("avaliacoes");
					queryClient.invalidateQueries(["avaliacao", props.data.id]);
					setRemoved(true);
				} else {
					handleToast(toastTitle, resp.statusText, 5000, "danger");
				}
			} catch (error: any) {
				if (axios.isAxiosError(error)) {
					const axiosError = error as AxiosError<any>;
					handleToast(toastTitle, axiosError.response?.data.message, 5000, "danger");
				}
			}
			setRemoveModal(false);
			setRemoveLoadingModal(false);
		}
	}

	function handleClickDuplicate(e: any) {
		e.stopPropagation();
		setDuplicateModal(true);
	}

	function handleClickDuplicateClose() {
		if (!duplicateLoadingModal) {
			setDuplicateModal(false);
		}
	}

	async function handleDuplicate() {
		if (!props.skeleton && props.data) {
			setDuplicateLoadingModal(true);
			let resp = await questionnaireService.duplicate(props.data.id!);
			if (resp.status === 201) {
				queryClient.refetchQueries("avaliacoes");
				queryClient.invalidateQueries(["avaliacao", props.data.id]);
				handleToast(toastTitle, "Duplicada com sucesso!", 5000);
			} else {
				handleToast(toastTitle, resp.statusText, 5000, "danger");
			}
			setDuplicateModal(false);
			setDuplicateLoadingModal(false);
		}
	}

	let skeletonButton = "";
	if (props.skeleton) {
		skeletonButton = "skeleton skeleton-button";
	}

	if (removed) {
		return <></>;
	}

	return (
		<>
			<ListCard onClick={handleClick}>
				<ListCard.Content>
					<ListCard.Item>
						<ListCard.Title skeleton={props.skeleton}>Titulo</ListCard.Title>
						<ListCard.Text skeleton={props.skeleton}>
							{props.data?.title ?? "..."} <span className="fw-lighter">#{props.data?.id}</span>
						</ListCard.Text>
					</ListCard.Item>
					<ListCard.Item>
						<ListCard.Title skeleton={props.skeleton}>Texto</ListCard.Title>
						<ListCard.Text skeleton={props.skeleton}>{props.data?.purpose ?? "..."}</ListCard.Text>
					</ListCard.Item>
				</ListCard.Content>
				<ListCard.Action>
					<Button variant="light" className={`me-2 ${skeletonButton}`} onClick={handleClickDuplicate}>
						<Icon path={mdiContentDuplicate} size={1} />
					</Button>
					<Button variant="light" className={`me-2 ${skeletonButton}`} onClick={handleClickRemove}>
						<Icon path={mdiTrashCanOutline} size={1} className="text-danger" />
					</Button>
					<Icon path={mdiChevronRight} size={1} className={`text-muted m-2 ${skeletonButton}`} />
				</ListCard.Action>
			</ListCard>

			<Modal show={removeModal} onHide={handleClickRemoveClose} centered>
				<Modal.Header closeButton>
					<Modal.Title>Remover Avaliação</Modal.Title>
				</Modal.Header>
				<Modal.Body className="text-center">{!removeLoadingModal ? "Deseja realmente remover esta avaliação?" : <Spinner />}</Modal.Body>
				{!removeLoadingModal && (
					<Modal.Footer>
						<Button variant="secondary" onClick={handleClickRemoveClose}>
							Cancelar
						</Button>
						<Button variant="danger" onClick={handleRemove}>
							Remover
						</Button>
					</Modal.Footer>
				)}
			</Modal>

			<Modal show={duplicateModal} onHide={handleClickDuplicateClose} centered>
				<Modal.Header closeButton>
					<Modal.Title>Duplicar Avaliação</Modal.Title>
				</Modal.Header>
				<Modal.Body className="text-center">{!duplicateLoadingModal ? "Deseja realmente duplicar esta avaliação?" : <Spinner />}</Modal.Body>
				{!duplicateLoadingModal && (
					<Modal.Footer>
						<Button variant="secondary" onClick={handleClickDuplicateClose}>
							Cancelar
						</Button>
						<Button onClick={handleDuplicate}>Duplicar</Button>
					</Modal.Footer>
				)}
			</Modal>
		</>
	);
}
