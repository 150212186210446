import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Alert, Button, Card, Col, Form, InputGroup, Row, Spinner } from "react-bootstrap";
import { useInfiniteQuery } from "react-query";
import { mdiAccountGroupOutline, mdiCardRemoveOutline, mdiChevronRight, mdiFilter, mdiFilterOutline, mdiLockOutline } from "@mdi/js";
import Icon from "@mdi/react";

import { ModuleEntity, ModulePermissionEntity, SkeletonCount } from "../../config/defines";
import { modulePermissionCheck } from "../../config/utils";
import { useAuth } from "../../context/AuthContext";
import { AccessControlListGetListResponseEntity } from "../../entities/AccessControlListEntity";
import AccessControlListService from "../../services/AccessControlListService";

import InfiniteScroll from "../../components/InfiniteScroll";
import GruposCard from "../../components/GruposCard";
import Layout from "../../components/Layout";

export default function Grupos() {
	const accessControlListService = new AccessControlListService();

	const navigate = useNavigate();
	const { user } = useAuth();

	const [filtro, setFiltro] = useState(false);
	const [filtroTexto, setFiltroTexto] = useState("");

	const [queryFiltro, setQueryFiltro] = useState<{ filtro: string }>({ filtro: "" });
	const { data, isLoading, isRefetching, isError, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfiniteQuery<AccessControlListGetListResponseEntity>(
		["grupos", queryFiltro],
		async ({ pageParam = 1 }) => fetchData(pageParam),
		{ getNextPageParam: (lastPage) => lastPage.nextPage }
	);

	async function fetchData(pagina: number) {
		const resp = await accessControlListService.getGroups(pagina, queryFiltro.filtro, user?.usuario.empresaId!);
		return resp.data;
	}

	async function fetchMoreData() {
		fetchNextPage();
	}

	function handleFiltro() {
		setFiltro(!filtro);
	}

	function handleAplicar() {
		setQueryFiltro({
			filtro: filtroTexto,
		});
	}

	return (
		<Layout>
			<h5 className="mt-4 mb-4 d-flex align-items-center fw-light">
				<Link to={"/acessos"} className="d-flex text-decoration-none">
					<Icon path={mdiLockOutline} size={1} className="me-1" /> Acessos
				</Link>
				<Icon path={mdiChevronRight} size={1} className="ms-1 me-1" />
				<Icon path={mdiAccountGroupOutline} size={1} className="me-1" /> Grupos
				{isRefetching && <Spinner size="sm" className="ms-1" variant="secondary" />}
				<div className="d-flex gap-2 float-right ms-auto" style={{ marginTop: -10, marginBottom: -10 }}>
					<Button className="d-inline-flex" variant="dark" onClick={handleFiltro}>
						<Icon path={filtro ? mdiFilter : mdiFilterOutline} size={1} />
					</Button>
					{modulePermissionCheck(user!, ModuleEntity.acessos, ModulePermissionEntity.editar) && (
						<Button
							variant="primary"
							onClick={() => {
								navigate("/grupoFormulario");
							}}
						>
							Cadastrar
						</Button>
					)}
				</div>
			</h5>

			{filtro && (
				<Row>
					<Col>
						<Card className="mb-4">
							<Card.Body>
								<Row>
									<Col>
										<Row>
											<Col md={12}>
												<InputGroup>
													<InputGroup.Text className="bg-white">Filtro</InputGroup.Text>
													<Form.Control
														placeholder="Busca por nome"
														value={filtroTexto}
														onChange={(event) => {
															setFiltroTexto(event.target.value);
														}}
														disabled={isLoading}
													/>
												</InputGroup>
											</Col>
										</Row>
									</Col>
									<Col sm={12} md="auto">
										<Button variant="primary" onClick={handleAplicar} disabled={isLoading}>
											Aplicar
										</Button>
									</Col>
								</Row>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			)}

			<Row className="mb-4">
				{isLoading &&
					Array.from({ length: Number(SkeletonCount) }, (_, index) => {
						return (
							<Col md={12} key={index}>
								<GruposCard skeleton />
							</Col>
						);
					})}
				{!isLoading && isError && (
					<Col md={12}>
						<Alert variant="secondary" className="text-center">
							Problema na operação. Tente novamente mais tarde
						</Alert>
					</Col>
				)}
				{!isLoading && data?.pages[0] && data?.pages[0].grupos.length === 0 && (
					<Col md={12}>
						<Alert variant="light" className="text-center p-4 d-flex justify-content-center align-items-center">
							<Icon path={mdiCardRemoveOutline} size={1} className="me-2" />
							Nenhum registro encontrado
						</Alert>
					</Col>
				)}
				{!isLoading &&
					data?.pages.map((dataPage, dataPageIndex) => {
						return (
							<React.Fragment key={dataPageIndex}>
								{dataPage.grupos.map((item, index) => {
									return (
										<Col md={12} key={index}>
											<GruposCard data={item} />
										</Col>
									);
								})}
							</React.Fragment>
						);
					})}
				<Col md={12} className="text-center">
					<InfiniteScroll more={hasNextPage} load={fetchMoreData} loader={<GruposCard skeleton />} loading={isFetchingNextPage} />
				</Col>
			</Row>
		</Layout>
	);
}
