import { useState } from "react";
import { Button, Col, Form, Image, OverlayTrigger, Row, Spinner, Tooltip } from "react-bootstrap";
import Icon from "@mdi/react";
import { mdiCheckboxBlankCircleOutline, mdiCheckboxMarkedCircleOutline, mdiInformation, mdiUpload } from "@mdi/js";

import { RespostaType } from "../../entities/QuestionnaireEntity";
import { QuestionEntity } from "../../entities/QuestionEntity";
import { QuestionnaireResponseQuestionFile } from "../../entities/QuestionnaireResponseEntity";

type FormParametroProps = {
	pergunta: QuestionEntity;
	resposta: RespostaType | null;
	onChange: (value: RespostaType) => void;
};

const fileEmpty = {} as QuestionnaireResponseQuestionFile;

export default function FormParametro(props: FormParametroProps) {
	const [value, setValue] = useState({
		...props.resposta,
		questionId: props.pergunta.id!,
		optionResponses: props.resposta?.optionResponses ?? [],
		files: props.resposta?.files && props.resposta?.files.length >= 1 ? props.resposta?.files : props.pergunta.allowImage ? [fileEmpty] : undefined,
	});
	const [loading, setLoading] = useState(-1);

	function handleChange(optionId: number, index: number) {
		let data = { ...value };
		let option = { optionId: optionId, index: index };
		let valueIndex = data.optionResponses.findIndex((item) => {
			return item.index === index;
		});
		if (valueIndex >= 0) {
			data.optionResponses[valueIndex] = option;
		} else {
			data.optionResponses.push(option);
		}
		setValue(data);
		props.onChange(data);
	}

	function handleAdicionar(event: any, index: number) {
		let file = event.target.files[0];
		if (file) {
			setLoading(index);
			let reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onloadend = () => {
				if (typeof reader.result === "string") {
					let data = { ...value };
					data.files![index] = {
						fileId: undefined,
						fileName: file.name,
						contentType: file.type,
						data: reader.result,
						blob: file,
					};
					setValue(data);
					props.onChange(data);
					setLoading(-1);
				}
			};
		}
	}

	return (
		<Row>
			{props.pergunta.optionGroups?.map((optionGroup, optionGroupIndex) => {
				return (
					<Col key={optionGroupIndex}>
						{optionGroup.options.map((option) => {
							let checked = value?.optionResponses?.some((item) => {
								return item.optionId === option.id;
							});
							return (
								<Col key={option.id} md={12} className="mb-2">
									<Button
										variant="outline-dark"
										className={`d-flex align-items-center w-100 gap-3 rounded-3 border text-start h-100 ${checked ? "active" : ""}`}
										onClick={() => {
											handleChange(option.id!, optionGroupIndex);
										}}
									>
										<div>
											<Icon path={checked ? mdiCheckboxMarkedCircleOutline : mdiCheckboxBlankCircleOutline} size={1} />
										</div>
										{option.title}
										{option.info && (
											<OverlayTrigger overlay={<Tooltip>{option.info}</Tooltip>}>
												<div className="ms-auto">
													<Icon path={mdiInformation} size={1} />
												</div>
											</OverlayTrigger>
										)}
									</Button>
								</Col>
							);
						})}
					</Col>
				);
			})}
			{props.pergunta.allowImage === true && (
				<Col lg={12}>
					<div className={`d-flex flex-column gap-2 justify-content-center align-items-center`}>
						<div>
							Imagem <span className="text-muted small">(Opcional)</span>
						</div>
						{value?.files?.map((file, fileIndex) => {
							return (
								<Form.Label
									className="mb-2 p-2 bg-white rounded-3 border-card"
									key={fileIndex}
									title={file.fileName}
									style={{ cursor: "pointer", width: 150, height: 150, borderStyle: "dashed", aspectRatio: 1 }}
								>
									<div className={`d-flex justify-content-center align-items-center`} style={{ width: "100%", height: "100%" }}>
										{loading === fileIndex ? (
											<Spinner className="mb-2" />
										) : (
											<>{file.data ? <Image src={file.data} style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "cover" }} /> : <Icon path={mdiUpload} size={2} />}</>
										)}
									</div>

									<Form.Control
										className="d-none"
										type="file"
										accept="image/*"
										onChange={(e) => {
											handleAdicionar(e, fileIndex);
										}}
									/>
								</Form.Label>
							);
						})}
					</div>
				</Col>
			)}
		</Row>
	);
}
