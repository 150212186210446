import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Button, Card, Col, Form, InputGroup, ListGroup, Modal, Row, Spinner } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import Select from "react-select";
import Icon from "@mdi/react";
import { mdiBookOutline, mdiBroom, mdiCardRemoveOutline, mdiFilter, mdiFilterOutline, mdiPlus } from "@mdi/js";

import { useToast } from "../../context/ToastContext";
import { SelectGroupStartStyle, SelectGroupStyle, SelectOptions } from "../../config/select";
import { SkeletonCount } from "../../config/defines";
import { QuestionnaireResponseGetResponse } from "../../entities/QuestionnaireResponseEntity";
import { QuestionnaireGetQuestionnairesResponse } from "../../entities/QuestionnaireEntity";
import QuestionnaireResponseService from "../../services/QuestionnaireResponseService";
import QuestionnaireService from "../../services/QuestionnaireService";
import ClientService from "../../services/ClientService";
import UnitService from "../../services/UnitService";

import AvaliacaoCard from "../../components/AvaliacaoCard";
import Layout from "../../components/Layout";
import { AxiosError, AxiosResponse } from "axios";
import { UnitPostRequest, UnitPostResponse } from "../../entities/UnitEntity";
import { useForm } from "react-hook-form";
import { ClientPostRequest, ClientPostResponse } from "../../entities/ClientEntity";

const toastTitle = "Avaliações";

export default function Avaliacoes() {
	const questionnaireResponseService = new QuestionnaireResponseService();
	const questionnaireService = new QuestionnaireService();
	const clientService = new ClientService();
	const unitService = new UnitService();

	const navigate = useNavigate();
	const { handleToast } = useToast(toastTitle);

	const [filtro, setFiltro] = useState(false);
	const [filtroQuestionnaire, setFiltroQuestionnaire] = useState<{ label: string; value: number } | null>();
	const [filtroClient, setFiltroClient] = useState<{ label: string; value: number } | null>();
	const [filtroUnit, setFiltroUnit] = useState<{ label: string; value: number } | null>();
	const [filtroQuery, setFiltroQuery] = useState<{ questionnaireId?: number; clientId?: number; unitId?: number }>();

	const [formModal, setFormModal] = useState(false);
	const [formQuestionnaireId, setFormQuestionnaireId] = useState<number>();
	const [formClient, setFormClient] = useState<{ label: string; value: number } | null>();
	const [formUnit, setFormUnit] = useState<{ label: string; value: number } | null>();

	const [clienteModal, setClienteModal] = useState(false);
	const [clienteSaving, setClienteSaving] = useState(false);

	const [unidadeModal, setUnidadeModal] = useState(false);
	const [unidadeSaving, setUnidadeSaving] = useState(false);

	const queryQuestionnaires = useQuery<QuestionnaireGetQuestionnairesResponse[]>(["avaliacoes"], () => fetchDataQuestionnaires());
	const queryClientes = useQuery(["clientes"], () => fetchDataClientes());
	const queryUnidades = useQuery(["unidades", filtroClient?.value], () => fetchDataUnidades(filtroClient?.value), { enabled: !!filtroClient?.value });
	const queryUnidadesForm = useQuery(["unidades", formClient?.value], () => fetchDataUnidades(formClient?.value), { enabled: !!formClient?.value });

	const formCliente = useForm();
	const formUnidade = useForm();

	const mutationClientePost = useMutation(mutateClientePost, { onSuccess: mutateClientePostSuccess, onError: mutateError });
	const mutationUnidadePost = useMutation(mutateUnidadePost, { onSuccess: mutateUnidadePostSuccess, onError: mutateError });

	const { data, isLoading, isRefetching, isError } = useQuery<QuestionnaireResponseGetResponse[]>(["avaliacoesRespostas", filtroQuery], () => fetchData(filtroQuery));

	useEffect(() => {
		let sessionFiltro = sessionStorage.getItem("avaliacoesFiltro");
		if (sessionFiltro) {
			let sessionFiltroJSON = JSON.parse(sessionFiltro);
			setFiltro(true);
			setFiltroQuestionnaire(sessionFiltroJSON.questionnaire);
			setFiltroClient(sessionFiltroJSON.client);
			setFiltroUnit(sessionFiltroJSON.unit);
			setFiltroQuery(sessionFiltroJSON.query);
		}
	}, []);

	async function fetchData(data: any) {
		const resp = await questionnaireResponseService.getQuestionnaireResponses(data);
		return resp.data;
	}

	async function fetchDataQuestionnaires() {
		const resp = await questionnaireService.getQuestionnaires();
		return resp.data;
	}

	async function fetchDataClientes() {
		let resp = await clientService.getClients();
		return resp.data;
	}

	async function fetchDataUnidades(clientId: any) {
		let resp = await unitService.getUnits(clientId);
		return resp.data;
	}

	async function mutateClientePost(data: ClientPostRequest) {
		setClienteSaving(true);
		return await clientService.post(data);
	}

	function mutateClientePostSuccess(resp: AxiosResponse<ClientPostResponse, any>) {
		handleToast(toastTitle, "Informações salvas com sucesso!", 5000);
		queryClientes.refetch();
		setClienteModal(false);
		setClienteSaving(false);
	}

	async function mutateUnidadePost(data: UnitPostRequest) {
		setUnidadeSaving(true);
		return await unitService.post(data);
	}

	function mutateUnidadePostSuccess(resp: AxiosResponse<UnitPostResponse, any>) {
		handleToast(toastTitle, "Informações salvas com sucesso!", 5000);
		queryUnidades.refetch();
		setUnidadeModal(false);
		setUnidadeSaving(false);
	}

	function mutateError(resp: AxiosError) {
		handleToast(toastTitle, `Problema na operação: ${resp.message}`, 5000, "danger");
		setUnidadeSaving(false);
	}

	function handleFiltro() {
		setFiltro(!filtro);
	}

	function handleLimpar() {
		setFiltroQuestionnaire(null);
		setFiltroClient(null);
		setFiltroUnit(null);
		setFiltroQuery({
			questionnaireId: undefined,
			clientId: undefined,
			unitId: undefined,
		});
		sessionStorage.removeItem("avaliacoesFiltro");
	}

	function handleAplicar() {
		setFiltroQuery({
			questionnaireId: filtroQuestionnaire?.value,
			clientId: filtroClient?.value,
			unitId: filtroUnit?.value,
		});

		let sessionData = {
			questionnaire: filtroQuestionnaire,
			client: filtroClient,
			unit: filtroUnit,
			query: {
				questionnaireId: filtroQuestionnaire?.value,
				clientId: filtroClient?.value,
				unitId: filtroUnit?.value,
			},
		};
		sessionStorage.setItem("avaliacoesFiltro", JSON.stringify(sessionData));
	}

	function handleFormModalClose() {
		setFormModal(false);
		setFormQuestionnaireId(undefined);
		setFormClient(undefined);
		setFormUnit(undefined);
	}

	const filtroQuestionnaireOptions = queryQuestionnaires.data?.map((item) => {
		return { label: `${item.title} #${item.id}`, value: item.id };
	});

	const filtroClientOptions = queryClientes.data?.map((item) => {
		return { label: `${item.name}`, value: item.id };
	});

	const filtroUnitOptions = queryUnidades.data?.map((item) => {
		return { label: `${item.name}`, value: item.id };
	});

	const formUnitOptions = queryUnidadesForm.data?.map((item) => {
		return { label: `${item.name}`, value: item.id };
	});

	return (
		<Layout header={{ exibirAplicativos: true }}>
			<h5 className="mt-4 mb-4 d-flex align-items-center fw-light">
				<Icon path={mdiBookOutline} size={1} className="me-1" /> Avaliações
				{isRefetching && <Spinner size="sm" className="ms-1" variant="secondary" />}
				<div className="d-flex gap-2 float-right ms-auto" style={{ marginTop: -10, marginBottom: -10 }}>
					<Button className="shadow-sm rounded-3" variant="light" onClick={handleFiltro}>
						<Icon path={filtro ? mdiFilter : mdiFilterOutline} size={1} />
					</Button>
					<Button
						variant="primary"
						className="rounded-3 shadow-sm"
						onClick={() => {
							setFormModal(true);
						}}
					>
						Cadastrar
					</Button>
				</div>
			</h5>

			{filtro && (
				<Row>
					<Col>
						<Card className="mb-4">
							<Card.Body>
								<Row>
									<Col>
										<Row>
											<Col>
												<InputGroup>
													<InputGroup.Text className="bg-white">Avaliação</InputGroup.Text>
													<Select
														placeholder={"Selecione"}
														value={filtroQuestionnaire}
														options={filtroQuestionnaireOptions}
														onChange={(value) => {
															setFiltroQuestionnaire(value);
														}}
														isClearable={true}
														styles={SelectGroupStartStyle}
														{...SelectOptions}
													/>
												</InputGroup>
											</Col>
											<Col>
												<InputGroup>
													<InputGroup.Text className="bg-white">Cliente</InputGroup.Text>
													<Select
														placeholder={"Selecione"}
														value={filtroClient}
														options={filtroClientOptions}
														onChange={(value) => {
															setFiltroClient(value);
															if (!value) {
																setFiltroUnit(null);
															}
														}}
														isClearable={true}
														styles={SelectGroupStartStyle}
														{...SelectOptions}
													/>
												</InputGroup>
											</Col>
											<Col>
												<InputGroup>
													<InputGroup.Text className="bg-white">Unidade</InputGroup.Text>
													<Select
														placeholder={"Selecione"}
														value={filtroUnit}
														options={filtroUnitOptions}
														onChange={(value) => {
															setFiltroUnit(value);
														}}
														isClearable={true}
														styles={SelectGroupStartStyle}
														{...SelectOptions}
													/>
												</InputGroup>
											</Col>
										</Row>
									</Col>
									<Col sm={12} md="auto" className="d-flex gap-2">
										<Button className="shadow-sm rounded-3" variant="light" onClick={handleLimpar} disabled={isLoading}>
											<Icon path={mdiBroom} size={1} />
										</Button>
										<Button className="shadow-sm rounded-3" variant="primary" onClick={handleAplicar} disabled={isLoading}>
											Aplicar
										</Button>
									</Col>
								</Row>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			)}

			<Row className="mb-4">
				{isLoading &&
					Array.from({ length: Number(SkeletonCount) }, (_, index) => {
						return (
							<Col md={12} key={index}>
								<AvaliacaoCard skeleton />
							</Col>
						);
					})}
				{!isLoading && isError && (
					<Col md={12}>
						<Alert variant="secondary" className="text-center">
							Problema na operação. Tente novamente mais tarde
						</Alert>
					</Col>
				)}
				{!isLoading && data && data.length === 0 && (
					<Col md={12}>
						<Alert variant="light" className="text-center p-4 d-flex justify-content-center align-items-center">
							<Icon path={mdiCardRemoveOutline} size={1} className="me-2" />
							Nenhum registro encontrado
						</Alert>
					</Col>
				)}
				{!isLoading &&
					data?.map((item) => {
						return (
							<Col md={12} key={item.id}>
								<AvaliacaoCard data={item} />
							</Col>
						);
					})}
			</Row>

			<Modal show={formModal && !clienteModal && !unidadeModal} onHide={handleFormModalClose} centered size="lg">
				<Modal.Header closeButton>
					<Modal.Title>Avaliação</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Col lg={6}>
							<Form.Group className="mb-3" controlId="name">
								<Form.Label>
									Cliente <small className="text-muted">(Opcional)</small>
								</Form.Label>
								<InputGroup>
									<Select
										placeholder={"Selecione"}
										value={formClient}
										options={filtroClientOptions}
										onChange={(value) => {
											setFormClient(value);
											setFormUnit(null);
										}}
										isClearable={true}
										isLoading={queryClientes.isLoading}
										styles={SelectGroupStyle}
										{...SelectOptions}
									/>
									<Button
										variant="light"
										className="border"
										onClick={() => {
											setClienteModal(true);
										}}
									>
										<Icon path={mdiPlus} size={1} />
									</Button>
								</InputGroup>
							</Form.Group>
						</Col>
						<Col lg={6}>
							<Form.Group className="mb-3" controlId="name">
								<Form.Label>
									Unidade <small className="text-muted">(Opcional)</small>
								</Form.Label>
								<InputGroup>
									<Select
										placeholder={"Selecione"}
										value={formUnit}
										options={formUnitOptions}
										onChange={(value) => {
											setFormUnit(value);
										}}
										isClearable={true}
										isDisabled={!formUnitOptions}
										isLoading={queryUnidades.isLoading}
										styles={SelectGroupStyle}
										{...SelectOptions}
									/>
									<Button
										variant="light"
										className="border"
										onClick={() => {
											setUnidadeModal(true);
										}}
										disabled={!formClient}
									>
										<Icon path={mdiPlus} size={1} />
									</Button>
								</InputGroup>
							</Form.Group>
						</Col>
						<Col lg={12}>
							<Form.Group className="mb-3" controlId="name">
								<Form.Label>Avaliação</Form.Label>
								<ListGroup>
									{queryQuestionnaires.data?.map((questionnaire, questionnaireKey) => {
										return (
											<ListGroup.Item
												key={questionnaireKey}
												action
												active={formQuestionnaireId === questionnaire.id}
												onClick={() => {
													setFormQuestionnaireId(questionnaire.id);
												}}
											>
												<span className="fw-medium">
													{questionnaire.title} <span className="fw-lighter">#{questionnaire.id}</span>
												</span>
											</ListGroup.Item>
										);
									})}
								</ListGroup>
							</Form.Group>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleFormModalClose}>
						Cancelar
					</Button>
					<Button
						variant="primary"
						onClick={() => {
							navigate("/avaliacaoFormulario/" + formQuestionnaireId + `?clientId=${formClient?.value}&unitId=${formUnit?.value}`);
						}}
						disabled={!formQuestionnaireId}
					>
						Continuar
					</Button>
				</Modal.Footer>
			</Modal>

			<Modal
				show={formModal && clienteModal && !unidadeModal}
				onHide={() => {
					setClienteModal(false);
				}}
				centered
			>
				<Form
					onSubmit={formCliente.handleSubmit((values) => {
						mutationClientePost.mutate({
							name: values.name,
							description: values.description,
						});
					})}
				>
					<Modal.Header closeButton>
						<Modal.Title>Adicionar Cliente</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Form.Group className="mb-3" controlId="name">
							<Form.Label>Nome</Form.Label>
							<Form.Control placeholder="Informe aqui" {...formCliente.register("name", { required: true })} disabled={clienteSaving} />
						</Form.Group>
						<Form.Group className="mb-3" controlId="description">
							<Form.Label>Descrição</Form.Label>
							<Form.Control placeholder="Informe aqui" {...formCliente.register("description", { required: true })} disabled={clienteSaving} />
						</Form.Group>
					</Modal.Body>
					{!clienteSaving && (
						<Modal.Footer>
							<Button
								variant="secondary"
								onClick={() => {
									setClienteModal(false);
								}}
							>
								Cancelar
							</Button>
							<Button variant="primary" type="submit">
								Salvar
							</Button>
						</Modal.Footer>
					)}
				</Form>
			</Modal>

			<Modal
				show={formModal && !clienteModal && unidadeModal}
				onHide={() => {
					setUnidadeModal(false);
				}}
				centered
			>
				<Form
					onSubmit={formUnidade.handleSubmit((values) => {
						mutationUnidadePost.mutate({
							name: values.name,
							description: values.description,
							clientId: Number(formClient?.value),
						});
					})}
				>
					<Modal.Header closeButton>
						<Modal.Title>Adicionar Unidade</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Form.Group className="mb-3" controlId="name">
							<Form.Label>Nome</Form.Label>
							<Form.Control placeholder="Informe aqui" {...formUnidade.register("name", { required: true })} disabled={unidadeSaving} />
						</Form.Group>
						<Form.Group className="mb-3" controlId="description">
							<Form.Label>Descrição</Form.Label>
							<Form.Control placeholder="Informe aqui" {...formUnidade.register("description", { required: true })} disabled={unidadeSaving} />
						</Form.Group>
					</Modal.Body>
					{!unidadeSaving && (
						<Modal.Footer>
							<Button
								variant="secondary"
								onClick={() => {
									setUnidadeModal(false);
								}}
							>
								Cancelar
							</Button>
							<Button variant="primary" type="submit">
								Salvar
							</Button>
						</Modal.Footer>
					)}
				</Form>
			</Modal>
		</Layout>
	);
}
