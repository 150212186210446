import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Alert, Button, Col, InputGroup, Row, Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import Select from "react-select";
import Icon from "@mdi/react";
import { mdiCardRemoveOutline, mdiChevronRight, mdiDomain, mdiLockOutline } from "@mdi/js";

import { ModuleEntity, ModulePermissionEntity, SkeletonCount } from "../../config/defines";
import { SelectGroupStartStyle, SelectOptions } from "../../config/select";
import { modulePermissionCheck } from "../../config/utils";
import { useAuth } from "../../context/AuthContext";
import { UnitGetUnitsResponse } from "../../entities/UnitEntity";
import UnitService from "../../services/UnitService";
import ClientService from "../../services/ClientService";

import UnidadesCard from "../../components/UnidadesCard";
import Layout from "../../components/Layout";

export default function Unidades() {
	const unitService = new UnitService();
	const clientService = new ClientService();

	const navigate = useNavigate();
	const { user } = useAuth();

	const [clientId, setClientId] = useState<{ label: string; value: number }>();

	const queryClientes = useQuery(["clientes"], () => fetchDataClientes());

	const { data, isLoading, isRefetching, isError } = useQuery<UnitGetUnitsResponse>(["unidades", clientId?.value], () => fetchData(clientId?.value), { enabled: !!clientId?.value });

	async function fetchData(clientId: any) {
		let resp = await unitService.getUnits(clientId);
		return resp.data;
	}

	async function fetchDataClientes() {
		let resp = await clientService.getClients();
		if (!clientId && resp.data[0]) {
			setClientId({ label: resp.data[0].name, value: resp.data[0].id });
		}
		return resp.data;
	}

	const formClienteOptions = queryClientes.data?.map((item) => {
		return { label: `${item.name}`, value: item.id };
	});

	return (
		<Layout>
			<h5 className="mt-4 mb-4 d-flex align-items-center fw-light">
				<Link to={"/acessos"} className="d-flex text-decoration-none">
					<Icon path={mdiLockOutline} size={1} className="me-1" /> Acessos
				</Link>
				<Icon path={mdiChevronRight} size={1} className="ms-1 me-1" />
				<Icon path={mdiDomain} size={1} className="me-1" /> Unidades
				{isRefetching && <Spinner size="sm" className="ms-1" variant="secondary" />}
				<div className="d-flex gap-2 float-right ms-auto fs-6" style={{ marginTop: -10, marginBottom: -10 }}>
					<InputGroup>
						<InputGroup.Text className="bg-white">Cliente</InputGroup.Text>
						<Select
							placeholder={"Selecione"}
							value={clientId}
							options={formClienteOptions}
							onChange={(value) => {
								if (value !== null) {
									setClientId(value);
								}
							}}
							styles={SelectGroupStartStyle}
							{...SelectOptions}
						/>
					</InputGroup>
					{modulePermissionCheck(user!, ModuleEntity.acessos, ModulePermissionEntity.editar) && (
						<Button
							variant="primary"
							className="rounded-3 shadow-sm"
							onClick={() => {
								navigate("/unidadeFormulario");
							}}
						>
							Cadastrar
						</Button>
					)}
				</div>
			</h5>

			<Row className="mb-4">
				{(isLoading || queryClientes.isLoading) &&
					Array.from({ length: Number(SkeletonCount) }, (_, index) => {
						return (
							<Col md={12} key={index}>
								<UnidadesCard skeleton />
							</Col>
						);
					})}
				{((!isLoading && isError) || (!queryClientes.isLoading && queryClientes.isError)) && (
					<Col md={12}>
						<Alert variant="secondary" className="text-center">
							Problema na operação. Tente novamente mais tarde
						</Alert>
					</Col>
				)}
				{((!isLoading && data && data.length === 0) || (!queryClientes.isLoading && queryClientes.data && queryClientes.data.length === 0)) && (
					<Col md={12}>
						<Alert variant="light" className="text-center p-4 d-flex justify-content-center align-items-center">
							<Icon path={mdiCardRemoveOutline} size={1} className="me-2" />
							Nenhum registro encontrado
						</Alert>
					</Col>
				)}
				{!isLoading &&
					data &&
					data.length >= 1 &&
					data.map((item, index) => {
						return (
							<Col md={12} key={item.id}>
								<UnidadesCard data={item} />
							</Col>
						);
					})}
			</Row>
		</Layout>
	);
}
