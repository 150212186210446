import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Card, Modal, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { useQuery } from "react-query";
import Icon from "@mdi/react";
import { mdiChevronLeft, mdiMapOutline, mdiOfficeBuildingOutline } from "@mdi/js";

import { EscalaComprometimento, EscalaRisco, EscalaVunerabilidade, ModuleEntity, ModulePermissionEntity } from "../../config/defines";
import { escalaCalculo, modulePermissionCheck } from "../../config/utils";
import { useAuth } from "../../context/AuthContext";
import { QuestionnaireResponseGetSummaryResponse } from "../../entities/QuestionnaireResponseEntity";
import QuestionnaireResponseService from "../../services/QuestionnaireResponseService";

import ListData from "../../components/ListData";
import Layout from "../../components/Layout";
import { useState } from "react";
import GoogleMap, { Map } from "google-maps-react-markers";

export default function AvaliacaoDetalhes() {
	const questionnaireResponseService = new QuestionnaireResponseService();

	const navigate = useNavigate();
	const { user } = useAuth();
	const { id } = useParams();

	const [mapaModal, setMapaModal] = useState(false);

	const { data, isLoading, isFetching, isRefetching } = useQuery<QuestionnaireResponseGetSummaryResponse>(["avaliacaoSumario", id], () => fetchData(id), { enabled: id !== undefined });

	async function fetchData(id: any) {
		let resp = await questionnaireResponseService.getSummary(id);
		return resp.data;
	}

	function handleMapLoad({ map }: { map: Map }) {
		let marker = new google.maps.Marker();
		marker.setPosition({ lat: data!.geoLocation.latitude, lng: data!.geoLocation.longitude });
		marker.setMap(map);
	}

	return (
		<Layout>
			<h5 className="mt-4 mb-4 d-flex align-items-center fw-light">
				<Link to={"/avaliacoes"} className="d-flex align-items-center text-decoration-none">
					<Icon path={mdiChevronLeft} size={1} className="me-1" /> <Icon path={mdiOfficeBuildingOutline} size={1} className="me-1" /> Detalhes da Avaliação
				</Link>
				{(isLoading || isFetching || isRefetching) && <Spinner size="sm" className="ms-1" variant="secondary" />}
				<div className="d-flex gap-2 float-right ms-auto" style={{ marginTop: -10, marginBottom: -10 }}>
					{modulePermissionCheck(user!, ModuleEntity.avaliacoes, ModulePermissionEntity.editar) && (
						<div className="d-flex gap-2">
							<Button
								variant="light"
								className="rounded-3 shadow-sm"
								onClick={() => {
									window.open(process.env.REACT_APP_API_BASE_URL + `api/v1/QuestionnaireResponse/${id}/Report`);
								}}
							>
								Visualizar Laudo
							</Button>
							<Button
								variant="light"
								className="rounded-3 shadow-sm"
								onClick={() => {
									navigate(`/avaliacaoFormulario/${data?.questionnaireId}/${id}`);
								}}
							>
								Editar Informações
							</Button>
						</div>
					)}
				</div>
			</h5>

			<Card className="mb-3">
				<Card.Body className="d-flex flex-column gap-2">
					<ListData>
						<ListData.Content>
							<ListData.Item>
								<ListData.Title>Identificação</ListData.Title>
								<ListData.Text skeleton={isLoading}>#{data?.id}&nbsp;</ListData.Text>
							</ListData.Item>
							<ListData.Item>
								<ListData.Title>Avaliação</ListData.Title>
								<ListData.Text skeleton={isLoading}>
									{data?.questionnaireName} <span className="fw-lighter">#{data?.questionnaireId}</span>&nbsp;
								</ListData.Text>
							</ListData.Item>
						</ListData.Content>
						{data?.clientId && (
							<ListData.Content>
								<ListData.Item>
									<ListData.Title>Cliente</ListData.Title>
									<ListData.Text skeleton={isLoading}>{data?.clientName}</ListData.Text>
								</ListData.Item>
								<ListData.Item>
									<ListData.Title>Unidade</ListData.Title>
									<ListData.Text skeleton={isLoading}>{data?.unitName ?? "-"}</ListData.Text>
								</ListData.Item>
							</ListData.Content>
						)}
						<ListData.Content>
							<ListData.Item>
								<ListData.Title>Usuário</ListData.Title>
								<ListData.Text skeleton={isLoading}>{data?.person.name}&nbsp;</ListData.Text>
							</ListData.Item>
							<ListData.Item>
								<ListData.Title>Data</ListData.Title>
								<ListData.Text skeleton={isLoading}>{data?.responseDate}&nbsp;</ListData.Text>
							</ListData.Item>
						</ListData.Content>
						<ListData.Content>
							<ListData.Item>
								<ListData.Title>Localização</ListData.Title>
								<ListData.Text skeleton={isLoading}>
									{data?.geoLocation.latitude}, {data?.geoLocation.longitude}&nbsp;
									<OverlayTrigger overlay={<Tooltip>Abrir Mapa</Tooltip>}>
										<Button
											variant="light"
											size="sm"
											className="d-inline-flex align-items-center py-0"
											onClick={() => {
												setMapaModal(true);
											}}
										>
											<Icon path={mdiMapOutline} size={0.8} />
										</Button>
									</OverlayTrigger>
								</ListData.Text>
							</ListData.Item>
						</ListData.Content>
					</ListData>
				</Card.Body>
			</Card>

			{data?.metrics?.map((metric, metricIndex) => {
				let escalaBase = EscalaRisco;
				if (metricIndex === 1) {
					escalaBase = EscalaVunerabilidade;
				}
				if (metricIndex === 2) {
					escalaBase = EscalaComprometimento;
				}
				let escala = escalaCalculo(escalaBase, metric.value);

				return (
					<Card className="mb-3" key={metricIndex}>
						<Card.Body className="d-flex flex-column gap-3">
							<ListData>
								<ListData.Content>
									<ListData.Item>
										<ListData.Title>{metric.title}</ListData.Title>
										<ListData.Text skeleton={isLoading}>{metric.value}</ListData.Text>
									</ListData.Item>
									<ListData.Item>
										<ListData.Title>Descrição</ListData.Title>
										<ListData.Text skeleton={isLoading}>
											{escala.index}. {escala.data.titulo}
										</ListData.Text>
									</ListData.Item>
								</ListData.Content>
							</ListData>
							<div className="d-flex position-relative m-2 rounded-3">
								<div className="d-flex flex-fill rounded-3 overflow-hidden position-relative" style={{ height: 50, border: "2px solid black" }}>
									{Array.from({ length: 10 }, (_, index) => {
										return (
											<div
												key={index}
												className="position-absolute"
												style={{ top: 0, bottom: 0, left: `calc(${index * 10}% - 2px)`, borderLeft: "2px dashed black", zIndex: 2 }}
											/>
										);
									})}
									<div className="flex-fill" style={{ background: "#34cc33", height: "100%", opacity: 1 }} />
									<div className="flex-fill" style={{ background: "#34cc33", height: "100%", opacity: 1 }} />
									<div className="flex-fill" style={{ background: "#339965", height: "100%", opacity: 1 }} />
									<div className="flex-fill" style={{ background: "#339965", height: "100%", opacity: 1 }} />
									<div className="flex-fill" style={{ background: "#ffff00", height: "100%", opacity: 1 }} />
									<div className="flex-fill" style={{ background: "#ffff00", height: "100%", opacity: 1 }} />
									<div className="flex-fill" style={{ background: "#ffbf00", height: "100%", opacity: 1 }} />
									<div className="flex-fill" style={{ background: "#ffbf00", height: "100%", opacity: 1 }} />
									<div className="flex-fill" style={{ background: "#c00201", height: "100%", opacity: 1 }} />
									<div className="flex-fill" style={{ background: "#c00201", height: "100%", opacity: 1 }} />
								</div>
								<div className="position-absolute" style={{ top: -5, left: `${metric.value}%`, height: 60, transform: "translateX(-25%)", zIndex: 3 }}>
									<div
										className="d-flex align-items-center justify-content-center bg-white rounded-3 shadow fw-bold border border-black border-2 small"
										style={{ marginLeft: -17.5, width: 35, height: 60 }}
									>
										{metric.value}
									</div>
								</div>
							</div>
							<div className="fw-light">{escala.data.descricao}</div>
						</Card.Body>
					</Card>
				);
			})}

			{modulePermissionCheck(user!, ModuleEntity.avaliacoes, ModulePermissionEntity.editar) && (
				<div className="d-flex gap-2 mb-4">
					<Button
						variant="light"
						className="rounded-3 shadow-sm"
						onClick={() => {
							window.open(process.env.REACT_APP_API_BASE_URL + `api/v1/QuestionnaireResponse/${id}/Report`);
						}}
					>
						Visualizar Laudo
					</Button>
					<Button
						variant="light"
						className="rounded-3 shadow-sm"
						onClick={() => {
							navigate(`/avaliacaoFormulario/${data?.questionnaireId}/${id}`);
						}}
					>
						Editar Informações
					</Button>
				</div>
			)}

			<Modal
				show={mapaModal}
				onHide={() => {
					setMapaModal(false);
				}}
				centered
				size="lg"
			>
				<Modal.Header closeButton>
					<Modal.Title>Mapa</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<ListData className="bg-white mb-3">
						<ListData.Content>
							<ListData.Item>
								<ListData.Title>Localização</ListData.Title>
								<ListData.Text skeleton={isLoading}>
									{data?.geoLocation.latitude}, {data?.geoLocation.longitude}&nbsp;
								</ListData.Text>
							</ListData.Item>
						</ListData.Content>
					</ListData>
					{data?.geoLocation.latitude && data?.geoLocation.longitude && (
						<div className="rounded-3 overflow-hidden border">
							<GoogleMap
								apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
								defaultZoom={15}
								defaultCenter={{ lat: data.geoLocation.latitude, lng: data.geoLocation.longitude }}
								options={{
									fullscreenControl: false,
									streetViewControl: false,
									zoomControlOptions: { position: 21 },
									mapTypeControlOptions: { position: 20 },
									mapTypeId: "roadmap",
								}}
								onGoogleApiLoaded={handleMapLoad}
								style={{ height: 350 }}
							/>
						</div>
					)}
				</Modal.Body>
			</Modal>
		</Layout>
	);
}
