import ApiService from "./ApiService";
import {
	QuestionnaireResponseDeleteResponse,
	QuestionnaireResponseGetResponse,
	QuestionnaireResponseGetSummaryResponse,
	QuestionnaireResponsePostQuestionFileRequest,
	QuestionnaireResponsePostQuestionFileResponse,
	QuestionnaireResponsePostRequest,
	QuestionnaireResponsePostResponse,
	QuestionnaireResponsePutRequest,
	QuestionnaireResponsePutResponse,
} from "../entities/QuestionnaireResponseEntity";

export default class QuestionnaireResponseService extends ApiService {
	public async getQuestionnaireResponses(data: any) {
		return await this.request.get<QuestionnaireResponseGetResponse[]>(`/api/v1/QuestionnaireResponses`, { params: data });
	}
	public async get(id: number) {
		return await this.request.get<QuestionnaireResponseGetResponse>(`/api/v1/QuestionnaireResponse/${id}`);
	}
	public async getSummary(id: number) {
		return await this.request.get<QuestionnaireResponseGetSummaryResponse>(`/api/v1/QuestionnaireResponse/${id}/Summary`);
	}
	public async post(id: number, data: QuestionnaireResponsePostRequest) {
		return await this.request.post<QuestionnaireResponsePostResponse>(`/api/v1/QuestionnaireResponse`, data, { params: { questionnaireId: id } });
	}
	public async postQuestionFile(data: QuestionnaireResponsePostQuestionFileRequest) {
		return await this.request.post<QuestionnaireResponsePostQuestionFileResponse>(`/api/v1/QuestionFile`, data);
	}
	public async put(id: number, data: QuestionnaireResponsePutRequest) {
		return await this.request.put<QuestionnaireResponsePutResponse>(`/api/v1/QuestionnaireResponse/${id}`, data);
	}
	public async delete(id: number) {
		return await this.request.delete<QuestionnaireResponseDeleteResponse>(`/api/v1/QuestionnaireResponse/${id}`);
	}
}
