import { DashboardGetCardResponseEntity, DashboardGetResponseEntity } from "../entities/DashboardEntity";
import ApiService from "./ApiService";

export default class DashboardService extends ApiService {
	public async get(clientId: number, unitId: number) {
		return await this.request.get<DashboardGetResponseEntity>(`/api/v1/Dashboard`, { params: { ClientId: clientId, UnitId: unitId } });
	}
	public async getCard(id: number, clientId: number, unitId: number) {
		return await this.request.get<DashboardGetCardResponseEntity>(`/api/v1/Dashboard/Card/${id}`, { params: { ClientId: clientId, UnitId: unitId } });
	}
}
