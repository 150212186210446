import ApiService from "./ApiService";
import { ClientGetClientsResponse, ClientGetResponse, ClientPostRequest, ClientPostResponse, ClientPutRequest, ClientPutResponse } from "../entities/ClientEntity";

export default class ClientService extends ApiService {
	public async getClients() {
		return await this.request.get<ClientGetClientsResponse>(`/api/v1/Clients`);
	}
	public async get(id: number) {
		return await this.request.get<ClientGetResponse>(`/api/v1/Client/${id}`);
	}
	public async post(data: ClientPostRequest) {
		return await this.request.post<ClientPostResponse>(`/api/v1/Client`, data);
	}
	public async put(id: number, data: ClientPutRequest) {
		return await this.request.put<ClientPutResponse>(`/api/v1/Client/${id}`, data);
	}
}
