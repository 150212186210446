import { useNavigate } from "react-router-dom";
import { Alert, Button, Col, Row, Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import Icon from "@mdi/react";
import { mdiAccountTieOutline, mdiCardRemoveOutline } from "@mdi/js";

import { ModuleEntity, ModulePermissionEntity, SkeletonCount } from "../../config/defines";
import { modulePermissionCheck } from "../../config/utils";
import { useAuth } from "../../context/AuthContext";
import ClientService from "../../services/ClientService";
import { ClientGetClientsResponse } from "../../entities/ClientEntity";

import ClientesCard from "../../components/ClientesCard";
import Layout from "../../components/Layout";

export default function Clientes() {
	const clientService = new ClientService();

	const navigate = useNavigate();
	const { user } = useAuth();

	const { data, isLoading, isRefetching, isError } = useQuery<ClientGetClientsResponse>(["clientes"], () => fetchData());

	async function fetchData() {
		let resp = await clientService.getClients();
		return resp.data;
	}

	return (
		<Layout>
			<h5 className="mt-4 mb-4 d-flex align-items-center fw-light">
				<Icon path={mdiAccountTieOutline} size={1} className="me-1" /> Clientes
				{isRefetching && <Spinner size="sm" className="ms-1" variant="secondary" />}
				<div className="d-flex gap-2 float-right ms-auto" style={{ marginTop: -10, marginBottom: -10 }}>
					{modulePermissionCheck(user!, ModuleEntity.acessos, ModulePermissionEntity.editar) && (
						<Button
							variant="primary"
							className="rounded-3 shadow-sm"
							onClick={() => {
								navigate("/clienteFormulario");
							}}
						>
							Cadastrar
						</Button>
					)}
				</div>
			</h5>

			<Row className="mb-4">
				{isLoading &&
					Array.from({ length: Number(SkeletonCount) }, (_, index) => {
						return (
							<Col md={12} key={index}>
								<ClientesCard skeleton />
							</Col>
						);
					})}
				{!isLoading && isError && (
					<Col md={12}>
						<Alert variant="secondary" className="text-center">
							Problema na operação. Tente novamente mais tarde
						</Alert>
					</Col>
				)}
				{!isLoading && data && data.length === 0 && (
					<Col md={12}>
						<Alert variant="light" className="text-center p-4 d-flex justify-content-center align-items-center">
							<Icon path={mdiCardRemoveOutline} size={1} className="me-2" />
							Nenhum registro encontrado
						</Alert>
					</Col>
				)}
				{!isLoading &&
					data &&
					data.length >= 1 &&
					data.map((item, index) => {
						return (
							<Col md={12} key={item.id}>
								<ClientesCard data={item} />
							</Col>
						);
					})}
			</Row>
		</Layout>
	);
}
